











































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {mapGetters} from 'vuex';

@Component({
  name: 'CopyToFriend',
  computed: {
    ...mapGetters({})
  }
})

export default class CopyToFriend extends Vue {

  @Prop() public selectedId!: number;
  private destinationEmail: string = null as unknown as string;
  private showModal = false;
  private confirmedRegisteredUser = false;
  private hasValidated = false;
  private showEmailAddressError = false;
  private messageEmailAddressError = '';

  get isCandidateForInvitation(): boolean {
    if (!this.hasValidated) {
      return true;
    }

    if (!this.isValidEmailAddress()) {
      return true;
    }

    return !this.confirmedRegisteredUser;
  }

  get isRegisteredUser(): boolean {
    if (!this.hasValidated) {
      return true;
    }

    if (!this.isValidEmailAddress()) {
      return true;
    }

    return this.confirmedRegisteredUser;
  }

  public toggleModalDialog() {
    this.showModal = !this.showModal;
    // this.destinationEmail = null as unknown as string;
    this.confirmedRegisteredUser = false;
    this.hasValidated = false;
  }

  @Watch('destinationEmail')
  private destinationEmailChanged(newVal: string) {
    this.$store.commit('setFriendsEmail', newVal);
    this.hasValidated = false;
    this.showEmailAddressError = false;
    this.messageEmailAddressError = '';
  }

  private isValidEmailAddress(): boolean {
    if (this.destinationEmail.endsWith('@education.nsw.gov.au')) {
      const fullStopPosition = this.destinationEmail.indexOf('.', 3);
      const ampersandPosition = this.destinationEmail.indexOf('@', 3);
      if (fullStopPosition > 0 && ampersandPosition > fullStopPosition) {
        return true;
      }
    }
    return false;
  }

  private async validateUser() {
    this.confirmedRegisteredUser = false;
    this.hasValidated = false;

    if (this.isValidEmailAddress()) {
      this.$store.commit('setFriendsEmail', this.destinationEmail);
      const friendsEmailValidationResult = await this.$store.dispatch('validateFriendEmail');
      if (friendsEmailValidationResult) {
        this.confirmedRegisteredUser = true;
      } else {
        this.showEmailAddressError = true;
        this.messageEmailAddressError = 'Your friend is not registered with the Digital Accelerator, ' +
            'please email digital.accelerator@det.nsw.edu.au with their details for an invitation to be sent to them';
        this.hasValidated = true;
        return;
      }
    } else {
      this.showEmailAddressError = true;
      this.messageEmailAddressError = 'Not a valid @education.nsw.gov.au email address';
      return;
    }
    this.hasValidated = true;
  }

  private async initiateCopy() {
    const result = await this.$store.dispatch('doCopyForAFriend');
    if (result) {
      this.destinationEmail = null as unknown as string;
      this.showModal = false;
      return;
    }
    this.showEmailAddressError = true;
    this.messageEmailAddressError = 'An error occurred attempting to copy the Learning Experience :(';
  }

}
