





















import {Component, Emit, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {ProjectModel} from '@/models/project_model';
import {Dimensions, Orientation} from '@/drawing/orientation';

@Component({
  computed: {
    ...mapGetters({
      imageUrls: 'getImageUrls',
      project: 'getCurrentProject'
    })
  }
})
export default class PreviewPageImageCards extends Vue {
  private imageUrls!: string[];
  private project!: ProjectModel;
  private currentPage = 0;

  private get orientation() {
    return Orientation.getOrientationStyle(this.project);
  }

  private get imageDimensions(): string {
    const dimensions: Dimensions = Orientation.getImageDimensions(this.project);
    return 'width: ' + dimensions.width + 'px; height: ' + dimensions.height + 'px;';
  }

  private get images(): string[] {
    return this.imageUrls;
  }

  private isSelected(page: number) {
    return page === this.currentPage;
  }

  @Emit('pageSelected')
  private pageSelected(page: number) {
    this.currentPage = page;
    this.$store.dispatch('setCurrentPage', page);
    return page;
  }

}

