<template>
    <div
            class="flex-column flex-grow-1 align-center"
            style="max-height: 50vh; overflow-y: auto"
    >
        <slot></slot>
    </div>
</template>

<script>
    export default {
      name: 'CardContent'
    };
</script>

<style scoped>

</style>
