






































































































































































import {Component, Emit, Ref, Vue, Watch} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {CreateProjectModel, ProjectModel} from '../../models/project_model';
import {PdfManagement} from '../../business/pdf_management';
import {ApiCallResult} from '../../rest_api/rest_api';
import Curriculum from '@/components/curriculum/Curriculum.vue';
import CardContent from '@/components/ui/CardContent.vue';

@Component({
  components: {CardContent, Curriculum},
  computed: {
    ...mapGetters({
      project: 'getPdfProject'
    })
  }
})
export default class NewProjectCard extends Vue {
  private project!: ProjectModel;
  private created: boolean = true;
  private createdMessage: string = null as unknown as string;
  private valid: boolean = false;
  private isCreatingProject: boolean = true;
  private isLoading: boolean = false;
  private isSelectCurriculum = false;
  private enableMarking: boolean = false;
  private defaultMarks: number = 1;
  private markQuestionsRequiredByDefault: boolean = false;
  private projectDescription: string = '';
  private projectTitle: string = '';
  private file: any = null;
  private rules: any = {
    required: (v) => !!v || 'Required.'
  };
  @Ref('form') private readonly form!: HTMLFormElement;
  @Ref('renderCanvas') private readonly renderCanvas!: HTMLCanvasElement;

  private readonly pdfManagement: PdfManagement = new PdfManagement();

  get getShowAlert() {
    return !this.created;
  }

  get currentPage(): number {
    return this.project.currentPageAnalyzing;
  }

  get totalPages(): number {
    return this.project.totalPages;
  }

  get creatingProject() {
    return this.isCreatingProject;
  }



  get isValid(): boolean {

    if (!this.file) {
      return false;
    }

    const fileName = this.file.name;

    if (fileName.length === 0) {
      return false;
    }

    if (!fileName.endsWith('.pdf')) {
      return false;
    }

    if (this.projectDescription.length === 0) {
      return false;
    }

    if (this.projectTitle.length === 0) {
      return false;
    }

    return this.projectTitle.length <= 50;


  }


  private async createProject() {
    this.isCreatingProject = false;

    if (!this.valid) {
      return;
    }

    await this.$store.dispatch('loading', true);

    const projectData: CreateProjectModel = {
      markQuestionsRequiredByDefault: this.markQuestionsRequiredByDefault,
      enableMarking: this.enableMarking,
      uid: this.$store.getters.getUid,
      file: this.file,
      projectDescription: this.projectDescription,
      projectName: this.projectTitle,
      userEmail: this.$store.getters.getUserModel.email,
      defaultMarks: this.defaultMarks
    };

    const result: ApiCallResult = await this.$store.dispatch('createPdfProject', projectData);

    if (!result.success && result.message) {
      this.created = false;
      this.createdMessage = result.message;
      await this.$store.dispatch('loading', false);
      this.isCreatingProject = true;
      this.isLoading = false;
      return false;
    }
    await this.pdfManagement.loadPDF(this.renderCanvas);
    await this.$store.dispatch('loading', false);
    this.isLoading = true;
    await this.pdfManagement.addAll();
    this.isLoading = false;
    this.created = true;
    this.isSelectCurriculum = true;
  }

  @Emit('created')
  private curriculumFinished(result: boolean) {
    this.isSelectCurriculum = false;
    this.isCreatingProject = true;
    this.projectDescription = '';
    this.projectTitle = '';
    this.file = null;
    this.enableMarking = false;
    this.markQuestionsRequiredByDefault = false;
    return true;
  }

  @Emit('cancel')
  private cancel() {
    return false;
  }

}


