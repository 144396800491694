























































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {MOVE_DIRECTION, MoveItem} from '@/models/preview_model';

@Component({})
export default class PreviewItemOrderDelete extends Vue {
  @Prop() public index!: string;

  private async doFirst() {
    const moveItem: MoveItem = {
      direction: MOVE_DIRECTION.FIRST,
      index: parseInt(this.index, 0)
    };
    await this.$store.dispatch('movePreviewItem', moveItem);
  }

  private async doUp() {
    const moveItem: MoveItem = {
      direction: MOVE_DIRECTION.UP,
      index: parseInt(this.index, 0)
    };
    await this.$store.dispatch('movePreviewItem', moveItem);
  }

  private async doDown() {
    const moveItem: MoveItem = {
      direction: MOVE_DIRECTION.DOWN,
      index: parseInt(this.index, 0)
    };
    await this.$store.dispatch('movePreviewItem', moveItem);
  }

  private async doLast() {
    const moveItem: MoveItem = {
      direction: MOVE_DIRECTION.LAST,
      index: parseInt(this.index, 0)
    };
    await this.$store.dispatch('movePreviewItem', moveItem);
  }

  @Emit('deleteItem')
  private doDelete() {
    return this.index;
  }
}
