









































import {Component, Vue} from 'vue-property-decorator';
import {DataTable} from '@nswdoe/doe-ui-core';
import {mapGetters} from 'vuex';
import {CurriculumLinkage, ProjectModel} from '../../../models/project_model';
import Curriculum from '@/components/curriculum/Curriculum.vue';

@Component({
  components: {
    DataTable,
    Curriculum
  },
  computed: {
    ...mapGetters({
      project: 'getCurrentProject',
      linkages: 'getCurrentProjectLinkages'
    })
  }
})
export default class PreviewCurriculum extends Vue {
  private project!: ProjectModel;
  private linkages!: CurriculumLinkage[];
  private headers: any[] = [
    {text: '#', value: 'id'},
    {text: 'Outcome Code', value: 'outcomeCode', groupable: true},
    {text: 'Outcome Description', value: 'outcomeDescription'},
    {text: 'Progression', value: 'progression'},
    {text: 'Progression Description', value: 'progressionDescription'},
  ];
  private isSelectCurriculum: boolean = false;

  private curriculumFinished() {
    this.isSelectCurriculum = false;
  }

  private addNewCurriculum() {
    this.isSelectCurriculum = true;
  }


}
