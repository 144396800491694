import {ProjectModel} from '@/models/project_model';

export interface Dimensions {
    width: number;
    height: number;
}

export class Orientation {
    public static getOrientationStyle(project: ProjectModel) {
        if (project.landscape) {
            return 'display: flex; flex-direction: row; flex-wrap: none;';
        }

        return 'display: flex; flex-direction: column; flex-wrap: none; overflow:auto;';
    }

    public static getImageDimensions(project: ProjectModel): Dimensions {

        let height: number = 150;
        let width: number = 150;

        if (project.landscape) {
            height = height * project.height / project.width;
        } else {
            width = width * project.width / project.height;
        }

        return {
            width,
            height
        } as Dimensions;

    }
}
