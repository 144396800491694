















































import {AppBar, AppIcon, BlockUI, Profile} from '@nswdoe/doe-ui-core';
import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {UserModel} from '@/models/user_model';

@Component({
  name: 'App',
  components: {
    AppBar,
    AppIcon,
    Profile,
    BlockUI
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
      userModel: 'getUserModel',
    })
  }
})
export default class App extends Vue {
  private isLoading!: boolean;
  private userModel!: UserModel;

  private get firstName(): string {
    if (this.userModel && this.userModel.email) {
      const names: string[] = this.userModel.email.split('_');
      if (names.length > 0) {
        return names[0];
      }
    }
    return '';
  }

  private get lastName(): string {
    if (this.userModel && this.userModel.email) {
      const names: string[] = this.userModel.email.split('.');
      if (names.length > 1) {
        const nameEmail: string[] = names[1].split('@');
        if (nameEmail.length > 1) {
          return nameEmail[0];
        }
      }
    }
    return '';
  }

  private async doLogout() {
    await this.$store.dispatch('logOutUser');
    this.$router.push({path: '/'}).catch((err) => {
      console.log(err);
    });
  }

  private async goHelp() {
    await this.$router.push('help');
  }
}

// TODO


