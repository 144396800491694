export enum DRAW_MODE {
    NONE,
    SHORT,
    PARAGRAPH,
    MULTIPLE_CHOICE,
    CHECKBOX,
    SCALE,
    TIME,
    DATE,
    SELECT,
    CUSTOM,
    YOUTUBE,
    IMAGE,
    DATE_TIME,
    LINE,
    ERASER,
    SECTION,
    CLEAR_ALL
}

export enum DRAW_STATE {
    ON,
    OFF
}
