import {MenuBarItem} from '@/drawing/MenuBarItems';
import {DRAW_MODE} from '@/drawing/Draw-Mode';
import {ProjectModel} from '@/models/project_model';

export enum MOVE_DIRECTION {
    UP,
    DOWN,
    FIRST,
    LAST
}

export interface MoveItem {
    index: number;
    direction: MOVE_DIRECTION;
}

export interface Choice {
    text: string;
    isCorrect: boolean;
    edit: boolean;
}


export interface PreviewItem {

    id: number;
    projectId: number;
    text: string;
    required: boolean;
    type: MenuBarItem;
    edit: boolean;
    options: Choice[];
    maxValue: number;
    minValue: number;
    fromLabel: string;
    toLabel: string;
    marking: boolean;
    marks: number;
    url?: string;
    width?: number;
    height?: number;
    imageFileName?: string;
    imageUrl?: string;
    helpText: string;
    generalFeedback?: any;
    correctAnswerFeedBack?: string;
    wrongAnswerFeedback?: string;
    curriculumLinkages: any[];

}

export interface SavePreviewItemRequest {
    index: number;
    previewItem: PreviewItem;
}

export interface UpdateProjectRequest {
    previewItems: PreviewItem[];
    projectModel: ProjectModel;
}

export interface ExportGoogleFormRequest {
    previewItems: PreviewItem[];
    projectId: number;
    projectName: string;
    projectDescription: string;
    uid: string;
    email: string;
}

export interface ExportGoogleClassroomRequest {
    previewItems: PreviewItem[];
    projectId: number;
    projectName: string;
    projectDescription: string;
    uid: string;
    courseId: string;
    topicId: string;
    courseTitle: string;
    courseDescription: string;
    email: string;
    className: string;
    topicName: string;
    lessonName: string;
}

export interface PublishedForms {
    uid: string;
    email: string;
    projectId: number;
    formId: string;
    editUrl: string;
    publishedUrl: string;
    sheet: string;
    sheetUrl: string;
    courseId: string;
    topic: string;
    courseName: string;
    topicName: string;
    lessonName: string;
    projectName: string;
    publishedDate: string;
    className: string;
}


export class FactoryPreviewItems {

    public static instancePreviewItem(
        menuBarItem: MenuBarItem,
        projectSettings: ProjectModel,
        edit?: boolean): PreviewItem {
        const previewItem: PreviewItem = {
            id: 0,
            projectId: projectSettings.id,
            type: menuBarItem,
            edit: edit ? edit : false,
            required: projectSettings.defaultRequired,
            text: '',
            maxValue: 0,
            minValue: 0,
            fromLabel: '',
            toLabel: '',
            options: [],
            marking: projectSettings.marking,
            marks: projectSettings.marking ? projectSettings.defaultMarks : 0,
            helpText: '',
            curriculumLinkages: []
        };

        if (menuBarItem.drawMode === DRAW_MODE.IMAGE || menuBarItem.drawMode === DRAW_MODE.YOUTUBE) {
            previewItem.url = '';

            if (menuBarItem.drawMode === DRAW_MODE.IMAGE) {
                previewItem.height = 0;
                previewItem.width = 0;
                previewItem.imageFileName = '';
            }
        }

        return previewItem;
    }

    public static instanceChoice(): Choice {
        return {
            text: '',
            edit: true,
            isCorrect: false
        } as Choice;
    }

}

export const menuBarItemDropDownMembers = [
    {
        drawMode: DRAW_MODE.SECTION,
        label: 'Lesson Content',
        matIcon: 'notes'
    }, {
        drawMode: DRAW_MODE.SHORT,
        label: 'Short Answer',
        matIcon: 'title'
    },
    {
        drawMode: DRAW_MODE.PARAGRAPH,
        label: 'Long Answer',
        matIcon: 'view_headline'
    },
    {
        drawMode: DRAW_MODE.MULTIPLE_CHOICE,
        label: 'Multiple Choice Answer',
        matIcon: 'check_box'
    },
    {
        drawMode: DRAW_MODE.CHECKBOX,
        label: 'Single Choice Answer',
        matIcon: 'radio_button_checked'
    },
    // {
    //     drawMode: DRAW_MODE.SELECT,
    //     label: 'Dropdown Single Choice',
    //     matIcon: 'arrow_drop_down_circle'
    // },
    {
        drawMode: DRAW_MODE.DATE,
        label: 'Date',
        matIcon: 'calendar_today'
    },
    {
        drawMode: DRAW_MODE.TIME,
        label: 'Time',
        matIcon: 'access_time'
    },
    {
        drawMode: DRAW_MODE.DATE_TIME,
        label: 'Date Time',
        matIcon: 'date_range'
    },
    {
        drawMode: DRAW_MODE.SCALE,
        label: 'Range',
        matIcon: 'linear_scale'
    }
];

