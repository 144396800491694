



































import { mapGetters } from 'vuex';

export default {
    name: 'Welcome',
    computed: {

    },
    asyncComputed: {
        ...mapGetters({
            getAuthenticationUrl: 'getAuthenticationUrl'
        }),
    },
};
