import {ProjectModel} from '@/models/project_model';
import store from '@/store';
import {TokenInfo} from '@/models/user_model';


export class UrlMethods {
  public static getServerURL(api) {
    const mode = process.env.NODE_ENV;
    const serverURL = mode && mode === 'development' ?
      'http://localhost:3000' : 'https://digitalaccelerator.test.education.nsw.gov.au';

    return serverURL + api;

  }

  public static getJSONHeader() {

    const defaultHeader = {
      'Content-Type': 'application/json',
    };

    const token: TokenInfo = store.getters.getToken;

    if (token) {
      defaultHeader['x-api-token'] = token.server;
    }

    return {
      ...defaultHeader
    };
  }

  public static getFormImageURL(fileName: string, project: ProjectModel) {
    return UrlMethods.getServerURL('/formImage/' + project.uid + '/' + fileName);
  }
}


