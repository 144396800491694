





















































































import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import ToolbarButton from '@/components/ToolbarButton.vue';
import {MenuBarItem, MenuBarItemHelper, menuBarItems} from '@/drawing/MenuBarItems';
import {DRAW_MODE, DRAW_STATE} from '@/drawing/Draw-Mode';
import {ProjectModel} from '@/models/project_model';
import {FactoryPreviewItems, PreviewItem} from '@/models/preview_model';
import {Dialog} from '@nswdoe/doe-ui-core';

@Component({
  components: {ToolbarButton, Dialog},
  computed: {
    ...mapGetters({
      drawState: 'getDrawState',
      project: 'getCurrentProject'
    })
  }
})
export default class ToolBar extends Vue {

  private items: MenuBarItem[] = menuBarItems;
  private drawState!: DRAW_STATE;
  private project!: ProjectModel;
  private showAddYoutube: boolean = false;
  private showClearAll: boolean = false;
  private youtubeUrl: string = '';
  private clearAllData: any = {
    key: DRAW_MODE.CLEAR_ALL,
    drawMode: DRAW_MODE.CLEAR_ALL
  };


  private async setMode(drawMode) {
    const newMode = parseInt(drawMode, 0);
    if (newMode === DRAW_MODE.CUSTOM) {
      const menuBarItem: MenuBarItem = MenuBarItemHelper.getMenuItemForDrawMode(newMode);
      const previewItem: PreviewItem = FactoryPreviewItems.instancePreviewItem(menuBarItem, this.project, true);
      await this.$store.dispatch('addPreviewItem', previewItem);
    } else if (newMode === DRAW_MODE.YOUTUBE) {
      this.showAddYoutube = true;
    } else if (newMode === DRAW_MODE.CLEAR_ALL) {
      this.showClearAll = true;
    } else {
      await this.$store.dispatch('setPreviewDrawMode', parseInt(drawMode, 0));
    }
  }

  private async addYoutube() {
    const menuBarItem: MenuBarItem = MenuBarItemHelper.getMenuItemForDrawMode(DRAW_MODE.YOUTUBE);
    const previewItem: PreviewItem = FactoryPreviewItems.instancePreviewItem(menuBarItem, this.project, false);
    previewItem.url = this.youtubeUrl;
    await this.$store.dispatch('addPreviewItem', previewItem);
    this.showAddYoutube = false;
    this.youtubeUrl = '';
  }

  private async handleClearAll(value: string) {
    if (value === 'Clear') {
      await this.clearAll();
    }
    this.showClearAll = false;
  }

  private async clearAll() {
    await this.$store.dispatch('setPreviewItems', []);
  }

}

