

export interface HelpContainer {
    url: string;
    label: string;
    extUrl: string;
}

export const helpContent: HelpContainer[] = [
    {
        url: 'https://www.youtube.com/embed/r65k03aWQM8',
        label: 'Teacher Overview',
        extUrl: 'https://youtu.be/r65k03aWQM8'
    },
    {
        url: 'https://www.youtube.com/embed/X2WWbAyA2Tw',
        label: 'Logging In',
        extUrl: 'https://youtu.be/X2WWbAyA2Tw'
    },
    {
        url: 'https://www.youtube.com/embed/X2WWbAyA2Tw',
        label: 'Converting Documents to PDF',
        extUrl: 'https://youtu.be/TYLDL_ghlec'
    },
    {
        url: 'https://www.youtube.com/embed/0uwhF0zkM7k',
        label: 'New Learning Experience',
        extUrl: 'https://youtu.be/0uwhF0zkM7k'
    },
    {
      url: 'https://www.youtube.com/embed/bPVcZTj9nME',
      label: 'Converting Content',
        extUrl: 'https://youtu.be/bPVcZTj9nME'
    },
    {
        url: 'https://www.youtube.com/embed/J-3i7xQsjSw',
        label: 'Converting Learning Materials',
        extUrl: 'https://youtu.be/J-3i7xQsjSw'
    },
    {
        url: 'https://www.youtube.com/embed/oafSTiXoFY0',
        label: 'Publishing your Learning Experience',
        extUrl: 'https://youtu.be/oafSTiXoFY0'
    },

    {
        url: 'https://www.youtube.com/embed/tS0Moo6tCgU',
        label: 'Marking',
        extUrl: 'https://youtu.be/tS0Moo6tCgU'
    },
    {
        url: 'https://www.youtube.com/embed/HgOcxu1vw18',
        label: 'Delete Learning Experience',
        extUrl: 'https://youtu.be/HgOcxu1vw18'
    },
];
