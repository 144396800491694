import {DRAW_MODE, DRAW_STATE} from '@/drawing/Draw-Mode';
import {
    ExportGoogleClassroomRequest,
    ExportGoogleFormRequest,
    MOVE_DIRECTION,
    MoveItem,
    PreviewItem,
    PublishedForms,
    SavePreviewItemRequest,
    UpdateProjectRequest
} from '@/models/preview_model';
import {CurriculumLinkage, ProjectModel} from '@/models/project_model';
import {ApiCallResult, RestAPI} from '@/rest_api/rest_api';
import {ActionCoordinates} from '@/drawing/Drawing';


export default {
    state: {
        drawMode: null as unknown as DRAW_MODE,
        previewItems: [] as PreviewItem[],
        currentProject: null as unknown as ProjectModel,
        currentProjectCurriculumLinkages: [] as CurriculumLinkage[],
        imageUrls: [] as string[],
        drawStatus: DRAW_STATE.OFF as DRAW_STATE,
        currentPage: 1,
        actionCoordinates: {
            currentX: 0,
            currentY: 0,
            originalX: 0,
            originalY: 0
        } as ActionCoordinates,
        textracts: [],
        previewDirty: false,
        currentProjectPublish: [] as PublishedForms[]
    },

    getters: {
        getDrawMode: (state): DRAW_MODE => {
            return state.drawMode;
        },

        getPreviewItems: (state): PreviewItem[] => {
            return state.previewItems;
        },

        getCurrentProject: (state): ProjectModel => {
            return state.currentProject;
        },

        getCurrentProjectLinkages: (state): CurriculumLinkage[] => {
            return state.currentProjectCurriculumLinkages;
        },

        getImageUrls: (state): string[] => {
            return state.imageUrls;
        },

        getDrawState: (state): DRAW_STATE => {
            return state.drawStatus;
        },

        getCurrentPage: (state): number => {
            return state.currentPage;
        },

        getActionCoordinates: (state): ActionCoordinates => {
            return state.actionCoordinates;
        },

        getTextracts: (state): any[] => {
            return state.textracts;
        },

        getPreviewDirty: (state): boolean => {
            return state.previewDirty;
        },

        getPublishedForms: (state): PublishedForms[] => {
            return state.currentProjectPublish;
        }


    },

    mutations: {
        mutateUpdateDrawMode: (state, drawMode: DRAW_MODE) => {
            state.drawMode = drawMode;
        },
        mutatePreviewItems: (state, previewItems: PreviewItem[]) => {
            state.previewItems = previewItems;
        },
        mutateAddPreviewItem: (state, previewItem: PreviewItem) => {
            state.previewItems.push(previewItem);
        },
        mutateCurrentProject: (state, project: ProjectModel) => {
            state.currentProject = project;
        },
        mutateCurrentProjectLinkages: (state, linkages: CurriculumLinkage[]) => {
            state.currentProjectCurriculumLinkages = linkages;
        },
        mutateImageUrls: (state, imageUrls: string[]) => {
            state.imageUrls = imageUrls;
        },

        mutateDrawState: (state, drawState: DRAW_STATE) => {
            state.drawStatus = drawState;
        },

        mutateCurrentPage: (state, page: number) => {
            state.currentPage = page;
            state.currentProject.currentPageAnalyzing = page;
        },

        mutateActionCoordinates: (state, actionCoordinates: ActionCoordinates) => {
            state.actionCoordinates = actionCoordinates;
        },

        mutateTextracts: (state, textracts: any[]) => {
            state.textracts = textracts;
        },

        mutatePreviewDirty: (state, dirty: boolean) => {
            state.previewDirty = dirty;
        },

        mutatePublishedForms: (state, published: PublishedForms[]) => {
            state.currentProjectPublish = published;
        }

    },

    actions: {

        setPreviewDrawMode(context, drawMode: DRAW_MODE) {
            context.commit('mutateUpdateDrawMode', drawMode);
        },

        setPreviewItems(context, previewItems: PreviewItem[]) {
            context.commit('mutatePreviewItems', previewItems);
        },

        addPreviewItem(context, previewItem: PreviewItem) {
            context.commit('mutateAddPreviewItem', previewItem);
            context.commit('mutatePreviewDirty', true);
        },

        async setCurrentProject(context, project: ProjectModel) {
            await context.dispatch('loading', true);
            context.commit('mutateCurrentProject', project);
            if (project) {
                const result = await RestAPI.linkages(project.id);
                if (result.success) {
                    context.commit('mutateCurrentProjectLinkages', result.data);
                } else {
                    context.commit('mutateCurrentProjectLinkages', [] as CurriculumLinkage[]);
                }
                const textractsResults = await RestAPI.getTextractLines(project.id);

                if (textractsResults.success) {
                    context.commit('mutateTextracts', textractsResults.data);
                }

                const previewItems = await RestAPI.getProjectPreviewItems(project.id);

                if (previewItems.success) {
                    context.commit('mutatePreviewItems', previewItems.data);
                    context.commit('mutatePreviewDirty', false);
                }

                await context.dispatch('refreshPublishedForms', project.id);
            }
            await context.dispatch('loading', false);
        },

        addLinkage(context, linkage: CurriculumLinkage) {
            const links: CurriculumLinkage[] = context.state.currentProjectCurriculumLinkages;
            links.push(linkage);
            context.commit('mutateCurrentProjectLinkages', links);
        },

        setImageUrls: ({commit}, imageUrls: string[]) => {
            commit('mutateImageUrls', imageUrls);
        },

        setDrawState: ({commit}, drawState: DRAW_STATE) => {
            commit('mutateDrawState', drawState);
        },

        setCurrentPage: ({commit}, page: number) => {
            commit('mutateCurrentPage', page);
        },

        uploadImageForForm: async (context, imageUrl): Promise<string> => {
            const imageFilename = context.state.currentProject.id + '_' + new Date().getTime();
            const result = await RestAPI.saveFormImage(imageUrl, imageFilename, context.state.currentProject);
            if (result.success) {
                console.log('Uploaded File:', imageFilename);
                return imageFilename;
            }

            return null as unknown as string;
        },

        setActionCoordinates: ({commit}, actionCoordinates: ActionCoordinates) => {
            commit('mutateActionCoordinates', actionCoordinates);
        },

        movePreviewItem: async (context, moveItem: MoveItem) => {
            const previewItems: PreviewItem[] = context.state.previewItems;

            if (
                (moveItem.direction === MOVE_DIRECTION.UP && moveItem.index > 0) ||
                (moveItem.direction === MOVE_DIRECTION.DOWN &&
                    moveItem.index < previewItems.length - 1)) {
                const offSet = moveItem.direction === MOVE_DIRECTION.UP ? -1 : 1;
                const previewItemOriginal = previewItems[moveItem.index];
                const previewItemOffset = previewItems[moveItem.index + offSet];
                previewItems[moveItem.index] = previewItemOffset;
                previewItems[moveItem.index + offSet] = previewItemOriginal;
                await context.commit('mutatePreviewItems', []);
                await context.commit('mutatePreviewItems', previewItems);
                context.commit('mutatePreviewDirty', true);
            } else if (moveItem.direction === MOVE_DIRECTION.FIRST) {
                const firstMoveTarget: PreviewItem = previewItems[moveItem.index];
                previewItems.splice(moveItem.index, 1);
                const updatePreviewItems: PreviewItem[] = [firstMoveTarget, ...previewItems];
                await context.commit('mutatePreviewItems', []);
                await context.commit('mutatePreviewItems', updatePreviewItems);
                context.commit('mutatePreviewDirty', true);
            } else if (moveItem.direction === MOVE_DIRECTION.LAST) {
                const lastMoveTarget: PreviewItem = previewItems[moveItem.index];
                previewItems.splice(moveItem.index, 1);
                const updatePreviewItems: PreviewItem[] = [...previewItems, lastMoveTarget];
                await context.commit('mutatePreviewItems', []);
                await context.commit('mutatePreviewItems', updatePreviewItems);
                context.commit('mutatePreviewDirty', true);
            }
        },

        deletePreviewItem: async (context, index) => {
            const previewItems: PreviewItem[] = context.state.previewItems;
            previewItems.splice(index, 1);
            await context.commit('mutatePreviewItems', []);
            await context.commit('mutatePreviewItems', previewItems);
            context.commit('mutatePreviewDirty', true);
        },

        setPreviewItemToEdit: async (context, index) => {
            const previewItems: PreviewItem[] = context.state.previewItems;
            previewItems[index].edit = true;
            await context.commit('mutatePreviewItems', []);
            await context.commit('mutatePreviewItems', previewItems);
        },

        savePreviewItem: async (context, request: SavePreviewItemRequest) => {
            const previewItems: PreviewItem[] = context.state.previewItems;
            previewItems[request.index] = request.previewItem;
            await context.commit('mutatePreviewItems', []);
            await context.commit('mutatePreviewItems', previewItems);
            context.commit('mutatePreviewDirty', true);
        },

        updateCurrentProject: (context, project: ProjectModel) => {
            context.commit('mutateCurrentProject', project);
            context.commit('mutatePreviewDirty', true);
        },

        saveCurrentProject: async (context, data: UpdateProjectRequest) => {
            context.commit('setIsLoading', true);
            await RestAPI.updateProject(data.projectModel);
            await context.dispatch('listUserProjects');
            await RestAPI.saveProjectPreviewItems(data.previewItems);
            context.commit('mutatePreviewDirty', false);
            context.commit('setIsLoading', false);
        },

        exportGoogleForm: async (context, exportGoogleFormRequest: ExportGoogleFormRequest): Promise<any> => {
            context.commit('setIsLoading', true);
            const result = await RestAPI.exportGoogleForm(exportGoogleFormRequest);
            if (!result.success) {
                console.log(result.message);
                return null;
            }
            context.commit('setIsLoading', false);
            return result.data;
        },

        exportGoogleClassroom: async (context, exportGoogleClassroomRequest: ExportGoogleClassroomRequest):
            Promise<any> => {
            context.commit('setIsLoading', true);
            const result = await RestAPI.exportToGoogleClassroom(exportGoogleClassroomRequest);
            if (!result.success) {
                console.log(result.message);
                return null;
            }
            context.commit('setIsLoading', false);
            return result.data;
        },

        refreshPublishedForms: async (context, projectId: number) => {
            await context.dispatch('loading', true);

            const result: ApiCallResult = await RestAPI.getPublished(projectId);

            context.commit('mutatePublishedForms', []);

            if (result.success) {
                context.commit('mutatePublishedForms', result.data);
            } else {
                console.error(result.message);
            }

            await context.dispatch('loading', false);
        }

    }

};
