


























import {Component, Vue} from 'vue-property-decorator';
import {HelpContainer, helpContent} from '@/business/help_content';
import HelpCard from '@/components/help/HelpCard.vue';


@Component({
  components: {HelpCard}
})
export default class UserHelp extends Vue {

  private helpContents: HelpContainer[] = helpContent;

}
