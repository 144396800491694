import axios from 'axios';
import {UrlMethods} from '@/api/UrlMethods';
import {CurriculumLinkage, ProjectModel} from '@/models/project_model';
import {ExportGoogleClassroomRequest, ExportGoogleFormRequest, PreviewItem} from '@/models/preview_model';
import store from '@/store';

export interface ApiCallResult {

  success: boolean;
  message?: string;
  data?: any;

}

export class RestAPI {

  public static async createProject(formData: FormData): Promise<ApiCallResult> {
    const url: string = UrlMethods.getServerURL('/uploadPDF');

    return await fetch(url, {
      method: 'POST',
      body: formData
    }).then((response) => response.json()).then((result: any) => {
      return {
        success: true,
        data: result
      } as ApiCallResult;
    }).catch((err) => {
      return {
        success: false,
        message: err
      } as ApiCallResult;
    });

  }

  public static async updateProject(projectModel: ProjectModel): Promise<ApiCallResult> {

    const url = '/updateProject';
    const body = {projectModel};
    return await this.doPost(url, body);

  }

  public static async getLoginUrl(): Promise<ApiCallResult> {
    const url = '/authUrl';
    return await this.doPost(url, {});
  }

  public static async getUserDetailsFromCode(code: string): Promise<ApiCallResult> {
    const body = {code};
    const url = '/authResult';
    return await this.doPost(url, body);
  }

  public static async uploadPageImage(imageUrl: string,
                                      page: number,
                                      projectModel: ProjectModel): Promise<ApiCallResult> {
    const body = {
      imageUrl,
      projectId: projectModel.id,
      uid: projectModel.uid,
      page
    };
    const url = '/uploadPage';
    return await this.doPost(url, body);
  }

  public static async getLearningAreas(): Promise<ApiCallResult> {
    const url = '/learningAreas';
    return await this.doPost(url, null);
  }

  public static async getStages(): Promise<ApiCallResult> {
    const url = '/stages';
    return await this.doPost(url, null);
  }

  public static async getOutcomes(learningArea: string, stage: string): Promise<ApiCallResult> {
    const url = '/outcomes';
    const body = {
      learningArea,
      stage
    };
    return await this.doPost(url, body);
  }

  public static async getSubElements(outcomeCode: string): Promise<ApiCallResult> {
    const url = '/progressionSubElements';
    const body = {outcomeCode};
    return await this.doPost(url, body);
  }

  public static async getFilteredProgInd(outcomeCode: string, subElement: string): Promise<ApiCallResult> {
    const body = {
      outcomeCode,
      subElement
    };
    const url = '/filteredProgressionIndicators';
    return await this.doPost(url, body);
  }

  public static async addLinkage(linkage: CurriculumLinkage): Promise<ApiCallResult> {
    const body = {
      linkage
    };
    const url = '/addLinkage';
    return await this.doPost(url, body);
  }

  public static async linkages(id: number): Promise<ApiCallResult> {
    const body = {id};
    const url = '/linkages';
    return await this.doPost(url, body);
  }

  public static async listProjects(uid: string): Promise<ApiCallResult> {
    const body = {uid};
    const url = '/listPdfProjects';
    return await this.doPost(url, body);
  }

  public static async deleteProjects(id: number): Promise<ApiCallResult> {
    const body = {id};
    const url = '/deleteProject';
    return await this.doPost(url, body);
  }

  public static async saveFormImage(
    imageUrl: string,
    imageFileName: string,
    project: ProjectModel): Promise<ApiCallResult> {
    const body = {
      imageFileName,
      imageUrl,
      uid: project.uid,
      id: project.id
    };

    const url = '/saveFormImage';
    return await this.doPost(url, body);
  }

  public static async getTextractLines(projectId): Promise<ApiCallResult> {
    const url = '/texttract';
    const body = {projectId};

    return await this.doPost(url, body);
  }

  public static async saveProjectPreviewItems(previewItems: PreviewItem[]): Promise<ApiCallResult> {
    const url = '/savePreviewItems';
    const body = {previewItems};

    return await this.doPost(url, body);
  }

  public static async getProjectPreviewItems(projectId: number): Promise<ApiCallResult> {
    const url = '/readPreviewItems';
    const body = {projectId};

    return await this.doPost(url, body);
  }

  public static async refreshToken(uid: string): Promise<ApiCallResult> {
    const url = '/refreshToken';
    const body = {uid};

    return await this.doPost(url, body);
  }

  public static async getTokenInfo(accessToken: string) {
    const url = '/getTokenInfo';
    const body = {access_token: accessToken};

    return await this.doPost(url, body);
  }

  public static async getClassrooms(uid: string): Promise<ApiCallResult> {
    const url = '/list/courses';
    const body = {uid};

    return await this.doPost(url, body);
  }

  public static async getTopics(uid: string, courseId: string): Promise<ApiCallResult> {
    const url = '/list/topics';
    const body = {uid, courseId};

    return await this.doPost(url, body);
  }

  public static async exportGoogleForm(exportGoogleFormsRequest: ExportGoogleFormRequest): Promise<ApiCallResult> {
    const url = '/export/google/forms';
    return await this.doPost(url, exportGoogleFormsRequest);
  }

  public static async exportToGoogleClassroom(exportGoogleClassroomRequest: ExportGoogleClassroomRequest):
    Promise<ApiCallResult> {
    const url = '/export/google/classroom/form';
    return await this.doPost(url, exportGoogleClassroomRequest);
  }

  public static async getPublished(projectId: number): Promise<ApiCallResult> {
    const url = '/publishedForms';
    const body = {projectId};
    return await this.doPost(url, body);
  }

  public static async validateFriendEmail(email: string): Promise<ApiCallResult> {
    const url = '/validateEmail';
    const body = {email};
    return await this.doPost(url, body);
  }

  public static async doCopyForAFriend(email: string, uid: string, projectId: number) {
    const url = '/copyToFriend';
    const body = {email, uid, projectId};
    return await this.doPost(url, body);
  }

  private static async doPost(url, body): Promise<ApiCallResult> {

    if (!body) {
      body = {};
    }

    if (!body.uid) {
      if (store.getters.getUid) {
        body.uid = store.getters.getUid;
      }
    }

    return await axios.post(UrlMethods.getServerURL(url), body, {
      headers: UrlMethods.getJSONHeader(),

    })
      .then((serverResult) => {
        return {
          success: true,
          data: serverResult.data
        } as ApiCallResult;
      }).catch((err) => {
        console.error('API POST ERROR', err);
        return {
          success: false,
          message: err
        } as ApiCallResult;
      });

  }

  private static async doGet(url: string): Promise<ApiCallResult> {
    return await axios.get(UrlMethods.getServerURL(url), {headers: UrlMethods.getJSONHeader()})
      .then((data) => {
        return {
          success: true,
          data
        } as ApiCallResult;
      }).catch((err) => {
        console.error(err);
        return {
          success: false,
          message: err
        } as ApiCallResult;
      });
  }


}

