import axios from 'axios';


const getProjects = () => {
  return Promise.resolve({Items: []}); // Promise.reject("Get projects api not implemented");
};

const createProject = (data) => {

    return Promise.resolve(true);

  // return Promise.reject("Get projects api not implemented", data);

  // return axios.post('', data, {
  //   headers: {
  //     'Content-Type': 'multipart/form-data'
  //   }
  // });
};

const deleteProject = (data) => {
  return Promise.resolve(true);
};

export default { getProjects, createProject, deleteProject };
