




















import {Component, Prop, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {Outcome} from '../../models/project_model';

@Component({

  computed: {
    ...mapGetters({
      outcomes: 'getOutcomes'
    })
  }
})
export default class OutcomeOnly extends Vue {
  @Prop() public selectedOutcomeCode: string | undefined;
  private outcomes!: Outcome[];

  private get initValue() {
    return this.selectedOutcomeCode;
  }

  private get outcome(): Outcome {
    console.log('getting', this.selectedOutcomeCode);
    const oc = this.outcomes.find((o) => o.outcomeCode === this.initValue) as unknown as Outcome;
    console.log('outcome', oc);
    return oc;
  }

}
