import Vue from 'vue';
import Vuex from 'vuex';
import projectsModule from './modules/projects.js';
import auth from '@/store/modules/auth';
import pdf_project from '@/store/modules/pdf_project';
import curriculum from '@/store/modules/curriculum';
import pdf_preview from '@/store/modules/pdf_preview';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLoading: false
    },
    mutations: {
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading;
        }
    },
    getters: {
        isLoading: (state) => state.isLoading,
    },
    actions: {
        loading: (injectee, payload) => {
            injectee.commit('setIsLoading', payload);
        }
    },
    modules: {
        projectsModule,
        auth,
        pdf_project,
        curriculum,
        pdf_preview
    },
});
