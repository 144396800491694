import {DRAW_MODE, DRAW_STATE} from './Draw-Mode';
import {FactoryPreviewItems, PreviewItem} from '../models/preview_model';
import {MenuBarItem, MenuBarItemHelper} from './MenuBarItems';
import store from '@/store/index';
import {UrlMethods} from '@/api/UrlMethods';
import {TextIndicator} from '@/drawing/Textract';

export interface DRAWMODESTATE {
    mode: DRAW_MODE;
    state: DRAW_STATE;
    previewItem?: PreviewItem;
}

export interface ActionCoordinates {
    originalX: number;
    originalY: number;
    currentX: number;
    currentY: number;
}

export interface ActionDimensions {
    width: number;
    height: number;
}

export interface LocationCoordinates {
    left: number;
    top: number;
}

export class Drawing {
    public static getDimensions(actionCoordinates: ActionCoordinates): ActionDimensions {
        const dimensions = {
            width: actionCoordinates.currentX - actionCoordinates.originalX,
            height: actionCoordinates.currentY - actionCoordinates.originalY
        };
        return dimensions;
    }

    public static loadImage(url): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = '*';
            img.addEventListener('load', () => resolve(img));
            img.addEventListener('error', (err) => reject(err));
            img.src = url;
        });
    }

    public static async clearCanvas(context: CanvasRenderingContext2D, img: HTMLImageElement) {

        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        if (img) {
            context.drawImage(img, 0, 0, context.canvas.width, context.canvas.height);
        }
    }

    public static setDrawModeCustom(drawModeState: DRAWMODESTATE, menuBarItem: MenuBarItem): DRAWMODESTATE {
        const previewItem: PreviewItem =
            FactoryPreviewItems.instancePreviewItem(menuBarItem, store.getters.getCurrentProject, true);
        if (drawModeState.mode === DRAW_MODE.YOUTUBE) {
            previewItem.marking = false;
            previewItem.marks = 0;
        }
        drawModeState.mode = DRAW_MODE.NONE;
        drawModeState.state = DRAW_STATE.OFF;
        drawModeState.previewItem = previewItem;
        return drawModeState;
    }

    public static getCurrentLocation(event: MouseEvent, canvasPreview: HTMLCanvasElement): LocationCoordinates {

        const rect = canvasPreview.getBoundingClientRect();

        return {
            left: event.clientX - rect.left,
            top: event.clientY - rect.top
        } as LocationCoordinates;

    }

    public static initActionCoordinates(event, canvasPreview: HTMLCanvasElement) {

        const rect = canvasPreview.getBoundingClientRect();
        return {
            originalX: event.clientX - rect.left,
            currentX: event.clientX - rect.left,
            originalY: event.clientY - rect.top,
            currentY: event.clientY - rect.top
        };

    }

    public static updateActionCoordinates(
        actionCoordinates: ActionCoordinates,
        event: MouseEvent,
        canvasPreview: HTMLCanvasElement): ActionCoordinates {
        const rect = canvasPreview.getBoundingClientRect();
        actionCoordinates.currentX = event.clientX - rect.left;
        actionCoordinates.currentY = event.clientY - rect.top;


        return actionCoordinates;
    }

    public static async drawBox(context: CanvasRenderingContext2D,
                                actionCoordinates: ActionCoordinates,
                                img: HTMLImageElement) {
        await this.clearCanvas(context, img);
        const actionDimensions = this.getDimensions(actionCoordinates);
        context.strokeStyle = 'navy';
        context.setLineDash([6, 4]);
        context.strokeRect(actionCoordinates.originalX,
            actionCoordinates.originalY,
            actionDimensions.width,
            actionDimensions.height);
        context.setLineDash([]);
    }

    public static async renderSelectedImage(context: CanvasRenderingContext2D,
                                            img: HTMLImageElement,
                                            actionCoordinates: ActionCoordinates,
                                            drawCanvas: HTMLCanvasElement): Promise<PreviewItem> {
        if (!img) {
            return null as unknown as PreviewItem;
        }

        const dimensions = this.getDimensions(actionCoordinates);

        context.canvas.height = dimensions.height;
        context.canvas.width = dimensions.width;

        context.clearRect(0, 0, dimensions.width, dimensions.height);

        context.drawImage(img, -actionCoordinates.originalX, -actionCoordinates.originalY,
            drawCanvas.width, drawCanvas.height);

        const imageUrl = context.canvas.toDataURL('image/png');

        const fileName = await store.dispatch('uploadImageForForm', imageUrl);
        const fileUrl = UrlMethods.getFormImageURL(fileName, store.getters.getCurrentProject);

        if (fileName) {

            const menuBarItemFileName: MenuBarItem = MenuBarItemHelper.getMenuItemForDrawMode(DRAW_MODE.IMAGE);

            const previewItemFileName: PreviewItem =
                FactoryPreviewItems.instancePreviewItem(menuBarItemFileName, store.getters.getCurrentProject, true);

            previewItemFileName.imageFileName = fileName;
            previewItemFileName.width = dimensions.width;
            previewItemFileName.height = dimensions.height;
            previewItemFileName.imageUrl = fileUrl;
            previewItemFileName.marking = false;
            previewItemFileName.marks = 0;
            previewItemFileName.required = store.getters.getCurrentProject.defaultRequired;

            return previewItemFileName;
        }

        const menuBarItem: MenuBarItem = MenuBarItemHelper.getMenuItemForDrawMode(DRAW_MODE.IMAGE);

        const previewItem: PreviewItem =
            FactoryPreviewItems.instancePreviewItem(menuBarItem, store.getters.getCurrentProject, false);

        return previewItem;

    }

    public static drawAccuracy(textIndicators: TextIndicator[], canvasContext: CanvasRenderingContext2D) {
        for (const textIndicator of textIndicators) {
            canvasContext.fillStyle = textIndicator.colour;
            canvasContext.fillRect(textIndicator.dimensions.Left, textIndicator.dimensions.Top,
                textIndicator.dimensions.Width, textIndicator.dimensions.Height);
            canvasContext.strokeStyle = textIndicator.borderColour;
            canvasContext.lineWidth = 1;
            canvasContext.strokeRect(textIndicator.dimensions.Left - 1, textIndicator.dimensions.Top - 1,
                textIndicator.dimensions.Width + 1, textIndicator.dimensions.Height + 1);
        }
    }

}
