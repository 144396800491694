























import {Component, Emit, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {LearningArea} from '@/models/project_model';

@Component({
    computed: {
        ...mapGetters({
            learningAreas: 'getLearningAreas'
        })
    }
})
export default class LearningAreas extends Vue {

    private learningAreas!: LearningArea[];
    private selectedLearningArea: string = '';

    @Emit('onSelect')
    private onSelect(item: LearningArea) {
        this.selectedLearningArea = item.learningArea;
    }

}

