import {TokenInfo, UserModel} from '@/models/user_model';
import {ApiCallResult, RestAPI} from '@/rest_api/rest_api';


export default {
  state: {
    user: null as unknown as UserModel,
    authUrl: null as unknown as string,
    friendsEmail: null as unknown as string,
    token: null as unknown as TokenInfo,
    code: null as unknown as string
  },

  getters: {

    getToken: (state) => {
      if (!state.token) {
        const authItem = localStorage.getItem('42');
        if (authItem) {
          state.token = JSON.parse(atob(authItem));
        } else {
          return null;
        }
      }

      return state.token;

    },
    getUid: (state) => {
      if (state.user !== null) {
        return state.user.uid;
      } else {
        // const storedAuth = localStorage.getItem('uid');
        // if (storedAuth) {
        //     state.user = JSON.parse(storedAuth);
        //     return state.user.uid;
        // } else {
        return false;
        // }

      }
    },
    getAuthenticationUrl: async (state) => {
      if (state.authUrl) {
        return state.authUrl;
      } else {
        const serverResult: ApiCallResult = await RestAPI.getLoginUrl();
        if (serverResult.success) {
          state.authUrl = serverResult.data;
        } else {
          console.log(serverResult.message);
        }
        return state.authUrl;
      }
    },
    getUserModel: (state) => {
      if (state.user) {
        return state.user;
      } else {
        return false;
      }
    },

    getFriendEmailAddress: (state) => state.friendsEmail,

  },

  mutations: {
    setUid(state, userId: UserModel) {
      state.user = userId;
    },

    setFriendsEmail(state, emailAddress: string) {
      state.friendsEmail = emailAddress;
    },

    mutateToken(state, token: string) {
      state.token = token;
      if (token) {
        localStorage.setItem('42', btoa(JSON.stringify(token)));
      } else {
        localStorage.removeItem('42');
      }

    },

    mutateCode(state, code: string) {
      state.code = code;
    }

  },

  actions: {
    async getUserFromCode({commit}, code: string): Promise<boolean> {

      commit('mutateCode', code);

      if (code === undefined || code === 'undefined') {
        return false;
      }
      const result: ApiCallResult = await RestAPI.getUserDetailsFromCode(code);
      if (result.success) {
        commit('mutateToken', result.data.token as TokenInfo);
        commit('setUid', result.data.user as UserModel);
        return true;
      } else {
        commit('mutateToken', null);
        commit('setUid', null);
        return false;
      }
    },

    async getUserFromToken(context): Promise<boolean> {
      const token: TokenInfo = context.getters.getToken;

      if (token?.server) {

        await context.dispatch('loading', true);
        const result: ApiCallResult = await RestAPI.getTokenInfo(token.server);
        await context.dispatch('loading', false);

        if (result.success) {
          context.commit('setUid', result.data.user);
          return true;
        }

        context.commit('mutateToken', null);

      }

      return false;

    },

    refreshToken: async (context): Promise<boolean> => {
      await context.dispatch('loading', true);
      const uid: string = context.getters.getUid;
      const result: ApiCallResult = await RestAPI.refreshToken(uid);
      await context.dispatch('loading', false);
      return result.success;
    },

    logOutUser: async (context) => {
      context.commit('setUid', null);
      context.commit('mutateToken', null);
    },

    validateFriendEmail: async (context) => {
      await context.dispatch('loading', true);
      const friendsEmail = context.getters.getFriendEmailAddress;
      const apiResult: ApiCallResult = await RestAPI.validateFriendEmail(friendsEmail);
      await context.dispatch('loading', false);
      return apiResult.success;
    },

    doCopyForAFriend: async (context) => {

      await context.dispatch('loading', true);
      const email = context.getters.getFriendEmailAddress;
      const uid = context.getters.getUid;
      const projectId = context.getters.getSelectedProject;
      const apiResult: ApiCallResult = await RestAPI.doCopyForAFriend(email, uid, projectId);
      await context.dispatch('loading', false);
      return apiResult.success;

    }
  }
};
