























































































import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {ProjectModel} from '../../../models/project_model';
import Quill from 'quill';


@Component({
  computed: {
    ...mapGetters({
      project: 'getCurrentProject'
    })
  }
})
export default class LessonPlan extends Vue {

  private project!: ProjectModel;
  private editorMainIdea!: Quill;
  private editorObjectives!: Quill;
  private editorContent!: Quill;
  private editorTeachersNotes!: Quill;
  private editorLanguage!: Quill;
  private editorAssessment!: Quill;
  private editorSampleActivities!: Quill;
  private editorResources!: Quill;

  private mounted() {
    this.editorMainIdea = new Quill('#mainIdea', {
      theme: 'snow'
    });

    this.editorMainIdea.setContents(this.project.mainIdeaRtf);

    this.editorMainIdea.on('text-change', (textValue, oldValue) => {
      this.project.mainIdea = this.editorMainIdea.getText();
      this.project.mainIdeaRtf = this.editorMainIdea.getContents();
      this.$store.dispatch('');
      this.$store.dispatch('updateCurrentProject', this.project);
    });

    this.editorObjectives = new Quill('#objectives', {
      theme: 'snow'
    });

    this.editorObjectives.setContents(this.project.objectivesRtf);

    this.editorObjectives.on('text-change', () => {
      this.project.objectivesRtf = this.editorObjectives.getContents();
      this.project.objectives = this.editorObjectives.getText();
      this.$store.dispatch('updateCurrentProject', this.project);
    });

    this.editorContent = new Quill('#content', {
      theme: 'snow'
    });

    this.editorContent.setContents(this.project.contentRtf);

    this.editorContent.on('text-change', () => {
      this.project.contentRtf = this.editorContent.getContents();
      this.project.content = this.editorContent.getText();
      this.$store.dispatch('updateCurrentProject', this.project);
    });

    this.editorTeachersNotes = new Quill('#teacherNotes', {
      theme: 'snow'
    });

    this.editorTeachersNotes.setContents(this.project.teachersNotesRtf);

    this.editorTeachersNotes.on('text-change', () => {
      this.project.teachersNotesRtf = this.editorTeachersNotes.getContents();
      this.project.teachersNotes = this.editorTeachersNotes.getText();
      this.$store.dispatch('updateCurrentProject', this.project);
    });

    this.editorLanguage = new Quill('#language', {
      theme: 'snow'
    });

    this.editorLanguage.setContents(this.project.languageRtf);

    this.editorLanguage.on('text-change', () => {
      this.project.languageRtf = this.editorLanguage.getContents();
      this.project.language = this.editorLanguage.getText();
      this.$store.dispatch('updateCurrentProject', this.project);
    });

    this.editorAssessment = new Quill('#assessment', {
      theme: 'snow'
    });

    this.editorAssessment.setContents(this.project.assessmentRtf);

    this.editorAssessment.on('text-change', () => {
      this.project.assessmentRtf = this.editorAssessment.getContents();
      this.project.assessment = this.editorAssessment.getText();
      this.$store.dispatch('updateCurrentProject', this.project);
    });

    this.editorSampleActivities = new Quill('#sampleActivities', {
      theme: 'snow'
    });

    this.editorSampleActivities.setContents(this.project.sampleActivitiesRtf);

    this.editorSampleActivities.on('text-change', () => {
      this.project.sampleActivitiesRtf = this.editorSampleActivities.getContents();
      this.project.sampleActivities = this.editorSampleActivities.getText();
      this.$store.dispatch('updateCurrentProject', this.project);
    });

    this.editorResources = new Quill('#resources', {
      theme: 'snow'
    });

    this.editorResources.setContents(this.project.resourcesRtf);

    this.editorResources.on('text-change', () => {
      this.project.resourcesRtf = this.editorResources.getContents();
      this.project.resources = this.editorResources.getText();
      this.$store.dispatch('updateCurrentProject', this.project);
    });
  }
}

