import {FactoryPreviewItems, PreviewItem} from '@/models/preview_model';
import {MenuBarItem, MenuBarItemHelper} from '@/drawing/MenuBarItems';
import {ProjectModel} from '@/models/project_model';
import {DRAW_MODE} from '@/drawing/Draw-Mode';
import {ActionCoordinates, Drawing, LocationCoordinates} from '@/drawing/Drawing';

export interface BoundingBox {
    Height: number;
    Left: number;
    Top: number;
    Width: number;
}

export interface SelectionBox {
    yOriginPercentage: number;
    xOriginPercentage: number;
    yFinishPercentage: number;
    xFinishPercentage: number;
}

export interface QuestionIndicator {
    found: boolean;
    start: boolean;
}

export interface TextIndicator {
    dimensions: BoundingBox;
    colour: string;
    borderColour: string;
    confidence: number;
    text: string;
}

export class Textract {

    public static findAccuracy(
        textractData: any,
        projectSettings: ProjectModel,
        mouseEvent: MouseEvent,
        drawMode: DRAW_MODE,
        canvas: HTMLCanvasElement
    ): TextIndicator[] {

        const textIndicators: TextIndicator[] = [];
        const locationCoordinates: LocationCoordinates = Drawing.getCurrentLocation(mouseEvent, canvas);
        const left = locationCoordinates.left / canvas.width;
        const top = locationCoordinates.top / canvas.height;

        const pageRecords: any[] = this.getPageRecords(textractData, projectSettings);
        if (pageRecords.length > 0) {
            for (const page of pageRecords) {
                for (const pageRecord of page.pageLines) {
                    if (pageRecord.Geometry?.BoundingBox && pageRecord.Confidence) {
                        if (
                            left >= pageRecord.Geometry.BoundingBox.Left &&
                            left <= pageRecord.Geometry.BoundingBox.Left + pageRecord.Geometry.BoundingBox.Width &&
                            top >= pageRecord.Geometry.BoundingBox.Top &&
                            top <= pageRecord.Geometry.BoundingBox.Top + pageRecord.Geometry.BoundingBox.Height
                        ) {

                            const colour: string = pageRecord.Confidence > 98 ? '#2E8B571f' :
                                pageRecord.Confidence > 90 ? '#FF8C001f' : '#ff00001f';
                            const borderColour: string = pageRecord.Confidence > 98 ? '#2E8B57ff' :
                                pageRecord.Confidence > 90 ? '#FF8C00ff' : '#ff0000ff';

                            textIndicators.push({
                                dimensions: {
                                    Left: pageRecord.Geometry.BoundingBox.Left * canvas.width,
                                    Height: pageRecord.Geometry.BoundingBox.Height * canvas.height,
                                    Top: pageRecord.Geometry.BoundingBox.Top * canvas.height,
                                    Width: pageRecord.Geometry.BoundingBox.Width * canvas.width
                                },
                                colour,
                                borderColour,
                                text: pageRecord.Text,
                                confidence: pageRecord.Confidence
                            } as TextIndicator);
                        }
                    }
                }
            }
        }
        return textIndicators;
    }

    public static createPreviewItemFromTextIndicator(
        textIndicator: TextIndicator,
        projectModel: ProjectModel,
        drawMode: DRAW_MODE
    ): PreviewItem {

        const menuBarItem: MenuBarItem = MenuBarItemHelper.getMenuItemForDrawMode(drawMode);
        const previewItem: PreviewItem = FactoryPreviewItems.instancePreviewItem(
            menuBarItem,
            projectModel,
            true
        );

        previewItem.text = textIndicator.text;

        return previewItem;
    }

    public static findText(
        textractData: any,
        projectSettings: ProjectModel,
        actionCoordinates: ActionCoordinates,
        drawMode: DRAW_MODE,
        canvas: HTMLCanvasElement
    ): PreviewItem[] {

        const selectionBox: SelectionBox = this.getSelectionBox(actionCoordinates, canvas);

        const menuBarItem: MenuBarItem = MenuBarItemHelper.getMenuItemForDrawMode(drawMode);

        const pageRecords: any[] = this.getPageRecords(textractData, projectSettings);
        if (pageRecords.length > 0) {

            const childrenWithinBox = pageRecords[0].pageLines.filter((child) =>
                this.withinGeometry(child.Geometry.BoundingBox, selectionBox));
            return this.returnQuestionsFromChildren(childrenWithinBox, menuBarItem, projectSettings);

        } else {
            return [];
        }


    }

    private static questionStartsTwoCharacter = [
        '1.', '2.', '3.', '4.', '5.', '6.', '7.', '8.', '9.',
        '1)', '2)', '3)', '4)', '5)', '6)', '7)', '8)', '9)',
        'a.', 'b.', 'c.', 'd.', 'e.', 'f.', 'g.', 'h.', 'i,', 'j.', 'k.', 'l.',
        'm.', 'n.', 'o,', 'p.', 'q.', 'r.', 's.', 't.', 'u.', 'v.', 'w.', 'x.', 'y.', 'z.',
        'a)', 'b)', 'c)', 'd)', 'e)', 'f)', 'g)', 'h)', 'i,', 'j)', 'k)', 'l)',
        'm)', 'n)', 'o),', 'p)', 'q)', 'r)', 's)', 't)', 'u)', 'v)', 'w)', 'x)', 'y)', 'z)'
    ];

    private static questionStartsThree = [
        '10.', '11.', '12.', '13.', '14.', '15.', '16.', '17.', '18.', '19.',
        '20.', '21.', '22.', '23.', '24.', '25.', '26.', '27.', '28.', '29.',
        '30.', '31.', '32.', '33.', '34.', '35.', '36.', '37.', '38.', '39.',
        '40.', '41.', '42.', '43.', '44.', '45.', '46.', '47.', '48.', '49.',
        '50.', '51.', '52.', '53.', '54.', '55.', '56.', '57.', '58.', '59.',
        '60.', '61.', '62.', '63.', '64.', '65.', '66.', '67.', '68.', '69.',
        '70.', '71.', '72.', '73.', '74.', '75.', '76.', '77.', '78.', '79.',
        '80.', '81.', '82.', '83.', '84.', '85.', '86.', '87.', '88.', '89.',
        '90.', '91.', '92.', '93.', '94.', '95.', '96.', '97.', '98.', '99.',
        '10)', '11)', '12)', '13)', '14)', '15)', '16)', '17)', '18)', '19)',
        '20)', '21)', '22)', '23)', '24)', '25)', '26)', '27)', '28)', '29)',
        '30)', '31)', '32)', '33)', '34)', '35)', '36)', '37)', '38)', '39)',
        '40)', '41)', '42)', '43)', '44)', '45)', '46)', '47)', '48)', '49)',
        '50)', '51)', '52)', '53)', '54)', '55)', '56)', '57)', '58)', '59)',
        '60)', '61)', '62)', '63)', '64)', '65)', '66)', '67)', '68)', '69)',
        '70)', '71)', '72)', '73)', '74)', '75)', '76)', '77)', '78)', '79)',
        '80)', '81)', '82)', '83)', '84)', '85)', '86)', '87)', '88)', '89)',
        '90)', '91)', '92)', '93)', '94)', '95)', '96)', '97)', '98)', '99)',
        'ii)', 'iv)', 'vi)', 'ix)', 'xi)', 'xv)',
        'ii.', 'iv.', 'vi.', 'ix.', 'xi.', 'xv.'
    ];

    private static questionStartsFour = [
        'iii.', 'iii)', 'vii.', 'vii)', 'xii.', 'xii)',
        '100.', '101.', '102.', '103.', '104.', '105.', '106.', '107.', '108.', '109.',
        '100)', '101)', '102)', '103)', '104)', '105)', '106)', '107)', '108)', '109)',
        '110.', '111.', '112.', '113.', '114.', '115.', '116.', '117.', '118.', '119.',
        '120.', '121.', '122.', '123.', '124.', '125.', '126.', '127.', '128.', '129.',
        '130.', '131.', '132.', '133.', '134.', '135.', '136.', '137.', '138.', '139.',
        '140.', '141.', '142.', '143.', '144.', '145.', '146.', '147.', '148.', '149.',
        '150.', '151.', '152.', '153.', '154.', '155.', '156.', '157.', '158.', '159.',
        '160.', '161.', '162.', '163.', '164.', '165.', '166.', '167.', '168.', '169.',
        '170.', '171.', '172.', '173.', '174.', '175.', '176.', '177.', '178.', '179.',
        '180.', '181.', '182.', '183.', '184.', '185.', '186.', '187.', '188.', '189.',
        '190.', '191.', '192.', '193.', '194.', '195.', '196.', '197.', '198.', '199.',
        '110)', '111)', '112)', '113)', '114)', '115)', '116)', '117)', '118)', '119)',
        '120)', '121)', '122)', '123)', '124)', '125)', '126)', '127)', '128)', '129)',
        '130)', '131)', '132)', '133)', '134)', '135)', '136)', '137)', '138)', '139)',
        '140)', '141)', '142)', '143)', '144)', '145)', '146)', '147)', '148)', '149)',
        '150)', '151)', '152)', '153)', '154)', '155)', '156)', '157)', '158)', '159)',
        '160)', '161)', '162)', '163)', '164)', '165)', '166)', '167)', '168)', '169)',
        '170)', '171)', '172)', '173)', '174)', '175)', '176)', '177)', '178)', '179)',
        '180)', '181)', '182)', '183)', '184)', '185)', '186)', '187)', '188)', '189)',
        '190)', '191)', '192)', '193)', '194)', '195)', '196)', '197)', '198)', '199)',
    ];

    private static getPageRecords(textractData: any, projectSettings: ProjectModel) {
        return textractData.filter((t) => {
            const findNonParse = t.page === projectSettings.currentPageAnalyzing + 1;
            const findParsed = parseInt(t.page, 0) === projectSettings.currentPageAnalyzing + 1;
            if (findNonParse) {
                return true;
            } else {
                return findParsed;
            }
        });
    }

    private static getSelectionBox(actionCoordinates: ActionCoordinates, canvas: HTMLCanvasElement) {
        return {
            yOriginPercentage: actionCoordinates.originalY / canvas.height,
            xOriginPercentage: actionCoordinates.originalX / canvas.width,
            yFinishPercentage: actionCoordinates.currentY / canvas.height,
            xFinishPercentage: actionCoordinates.currentX / canvas.width
        };
    }


    private static returnQuestionsFromChildren(
        childrenWithinBox: any[],
        menuBarItem: MenuBarItem,
        projectModel: ProjectModel): PreviewItem[] {
        const questions: PreviewItem[] = [];
        let previousLine = '';
        for (const child of childrenWithinBox) {

            const textBlock: string = child.Text;
            const questionResult: QuestionIndicator = this.findQuestionIndicator(textBlock);
            if (questionResult.found) {

                if (questionResult.start) {
                    if (previousLine > '') {
                        const question: PreviewItem = this.createPreviewItem(previousLine, menuBarItem, projectModel);
                        questions.push(question);
                    }
                    previousLine = textBlock;
                } else {
                    previousLine += ' ' + textBlock;
                    const question: PreviewItem = this.createPreviewItem(previousLine, menuBarItem, projectModel);
                    questions.push(question);
                    previousLine = '';
                }

            } else {
                previousLine += ' ' + textBlock;
            }

        }
        if (previousLine > '') {
            const question: PreviewItem = this.createPreviewItem(previousLine, menuBarItem, projectModel);
            questions.push(question);
        }
        return questions;
    }

    private static createPreviewItem(
        previousLine: string,
        menuBarItem: MenuBarItem,
        projectModel: ProjectModel): PreviewItem {

        const previewItem: PreviewItem = FactoryPreviewItems.instancePreviewItem(
            menuBarItem,
            projectModel,
            false
        );

        previewItem.text = previousLine;
        previewItem.projectId = projectModel.id;

        return previewItem;
    }

    private static findQuestionIndicator(textBlock: string): QuestionIndicator {

        const two = textBlock.substring(0, 2).toLowerCase();
        const three = textBlock.substring(0, 3).toLowerCase();
        const four = textBlock.substring(0, 4).toLowerCase();

        if (this.questionStartsFour.findIndex((q) => q === four) >= 0) {

            return {found: true, start: true};
        } else if (this.questionStartsTwoCharacter.findIndex((q) => q === two) >= 0) {

            return {found: true, start: true};
        } else if (this.questionStartsThree.findIndex((q) => q === three) >= 0) {

            return {found: true, start: true};
        } else if (textBlock.startsWith('question')) {

            return {found: true, start: true};
        } else if (textBlock.endsWith('?') || textBlock.endsWith('=')) {

            return {found: true, start: false};
        }
        return {found: false, start: false};
    }

    private static withinGeometry(boundingBox: BoundingBox, selectionBox: SelectionBox): boolean {
        return boundingBox.Top >= selectionBox.yOriginPercentage && boundingBox.Top <= selectionBox.yFinishPercentage
            && boundingBox.Left >= selectionBox.xOriginPercentage && boundingBox.Left <= selectionBox.xFinishPercentage;
    }


}
