













































































































import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {CurriculumLinkage, ProjectModel} from '@/models/project_model';
import {DRAW_MODE, DRAW_STATE} from '@/drawing/Draw-Mode';
import {Dimensions} from '@/drawing/orientation';
import {UrlMethods} from '@/api/UrlMethods';
import {ActionCoordinates, Drawing} from '@/drawing/Drawing';
import {PreviewItem} from '@/models/preview_model';
import {TextIndicator, Textract} from '@/drawing/Textract';
import {Alert, Dialog, DataTable} from '@nswdoe/doe-ui-core';

@Component({
  components: {Alert, Dialog, DataTable},
  computed: {
    ...mapGetters({
      project: 'getCurrentProject',
      drawMode: 'getDrawMode',
      drawState: 'getDrawState',
      currentPage: 'getCurrentPage',
      actionCoordinates: 'getActionCoordinates',
      projectTextract: 'getTextracts',
      linkages: 'getCurrentProjectLinkages',
      previewItems: 'getPreviewItems'
    })
  }
})
export default class DrawCanvas extends Vue {
  @Ref('drawCanvas') public readonly drawCanvas!: HTMLCanvasElement;
  @Ref('renderCanvas') public readonly renderCanvas!: HTMLCanvasElement;
  @Ref('tableCurriculumSelection') public readonly !: DataTable;
  @Prop({default: 1}) public page!: number;
  private img!: HTMLImageElement;
  private project!: ProjectModel;
  private drawMode!: DRAW_MODE;
  private drawState!: DRAW_STATE;
  private previewItems!: PreviewItem[];
  private dimensions: Dimensions = {
    height: 10,
    width: 10
  };
  private actionCoordinates!: ActionCoordinates;
  private textract: any = {};
  private projectTextract!: any[];
  private linkages!: CurriculumLinkage[];
  private textIndicators: TextIndicator[] = [];
  private previewItemsCreatedText: string = ' Items has been created';
  private previewItemsCreatedCount: number = 0;
  private showPreviewItemsCreated: boolean = false;
  private showLinkCurriculumOption: boolean = false;
  private showLinkBulkCurriculum: boolean = false;
  private headers: any[] = [
    {text: '#', value: 'id'},
    {text: 'Outcome Code', value: 'outcomeCode', groupable: true},
    {text: 'Outcome Description', value: 'outcomeDescription'},
    {text: 'Progression', value: 'progression'},
    {text: 'Progression Description', value: 'progressionDescription'},
  ];
  private selectedCurriculumItems: CurriculumLinkage[] = [];

  private async mounted() {
    if (this.project && this.project.landscape) {
      this.dimensions.height = window.screen.height - 453;
      this.dimensions.width = this.dimensions.height * this.project.width / this.project.height;
    } else if (this.project) {
      {
        this.dimensions.height = window.screen.height - 335;
        this.dimensions.width = this.dimensions.height * this.project.width / this.project.height;
      }
    }

    this.img = await Drawing.loadImage(this.url);
    if (this.img && this.canvasContext) {
      await Drawing.clearCanvas(this.canvasContext, this.img);
    }
    await this.$store.dispatch('setCurrentPage', this.page);
  }

  private get canvasContext(): CanvasRenderingContext2D {
    if (this.drawCanvas) {
      return this.drawCanvas.getContext('2d') as unknown as CanvasRenderingContext2D;
    }
    return null as unknown as CanvasRenderingContext2D;
  }

  private get renderCanvasContext2d(): CanvasRenderingContext2D {
    if (this.renderCanvas) {
      return this.renderCanvas.getContext('2d') as unknown as CanvasRenderingContext2D;
    }
    return null as unknown as CanvasRenderingContext2D;
  }

  private get url(): string {
    if (this.project) {
      const imagePath: string = '/projectPageImage/' + this.project.uid + '/' + this.project.id + '/' + (this.page + 1);
      return UrlMethods.getServerURL(imagePath);
    }
    return null as unknown as string;
  }

  @Watch('drawState')
  private setCanvasCursor() {
    if (this.drawState === DRAW_STATE.OFF) {
      this.drawCanvas.style.cursor = 'default';
    } else {
      this.drawCanvas.style.cursor = 'crosshair';
    }
  }

  private get floatingWindowClass(): string {
    if (this.textIndicators.length === 0 || !this.project) {
      return '';
    }

    if (this.textIndicators[0].dimensions.Top > this.project.height * 0.70) {
      return 'floatingWindowTop';
    }

    return 'floatingWindowBottom';
  }

  @Watch('page')
  private async pageChanged(newPage: number, oldPage: number) {
    this.img = await Drawing.loadImage(this.url);
    if (this.img && this.canvasContext) {
      await Drawing.clearCanvas(this.canvasContext, this.img);
    }
    this.textract = this.projectTextract[newPage];
  }

  private async mouseDown($event) {

    if (this.textIndicators.length > 0 && this.drawMode) {
      const previewItem = Textract.createPreviewItemFromTextIndicator(
          this.textIndicators[0],
          this.project,
          this.drawMode);

      if (previewItem) {
        await this.$store.dispatch('addPreviewItem', previewItem);
      }
      return;
    }

    if (this.drawState === DRAW_STATE.OFF && this.drawMode) {
      await this.$store.dispatch('setDrawState', DRAW_STATE.ON);
      const actionCoordinates: ActionCoordinates = Drawing.initActionCoordinates($event, this.drawCanvas);
      await this.$store.dispatch('setActionCoordinates', actionCoordinates);
    } else {
      await this.$store.dispatch('setDrawState', DRAW_STATE.OFF);
      if (this.drawMode === DRAW_MODE.IMAGE) {
        const previewItem = await
            Drawing.renderSelectedImage(this.renderCanvasContext2d, this.img, this.actionCoordinates, this.drawCanvas);
        if (previewItem) {
          await this.$store.dispatch('addPreviewItem', previewItem);
        }
      } else if (
          this.drawMode !== DRAW_MODE.NONE && this.drawMode !== DRAW_MODE.CUSTOM && this.drawMode !== DRAW_MODE.YOUTUBE
          && this.drawMode !== DRAW_MODE.ERASER && this.drawMode !== DRAW_MODE.LINE
      ) {
        const newPreviewItems: PreviewItem[] =
            Textract.findText(this.projectTextract, this.project, this.actionCoordinates,
                this.drawMode, this.drawCanvas);

        if (newPreviewItems.length === 1) {
          newPreviewItems[0].edit = true;
          await this.$store.dispatch('addPreviewItem', newPreviewItems[0]);

        } else if (newPreviewItems.length > 1) {
          newPreviewItems.forEach((item) => this.$store.dispatch('addPreviewItem', item));
          this.previewItemsCreatedCount = newPreviewItems.length;
          this.showPreviewItemsCreated = true;
          this.showLinkCurriculumOption = true;
        }

      }
      await Drawing.clearCanvas(this.canvasContext, this.img);
    }
  }

  private async mouseMove($event) {
    if (this.drawState === DRAW_STATE.ON) {
      const actionCoordinates = Drawing.updateActionCoordinates(this.actionCoordinates, $event, this.drawCanvas);
      await this.$store.dispatch('setActionCoordinates', actionCoordinates);
      if (this.drawMode !== DRAW_MODE.YOUTUBE && this.drawMode !== DRAW_MODE.CUSTOM
          && this.drawMode !== DRAW_MODE.NONE) {
        await Drawing.drawBox(this.canvasContext, this.actionCoordinates, this.img);
      }
    } else {
      await Drawing.clearCanvas(this.canvasContext, this.img);
      this.textIndicators = Textract.findAccuracy(this.projectTextract, this.project,
          $event, this.drawMode, this.drawCanvas);
      if (this.textIndicators.length > 0) {
        this.drawCanvas.style.cursor = 'pointer';
      } else {
        this.drawCanvas.style.cursor = 'default';
      }
      Drawing.drawAccuracy(this.textIndicators, this.canvasContext);
    }
  }

  private get accuracyInfo(): TextIndicator {
    if (this.textIndicators.length > 0) {
      return this.textIndicators[0];
    }
    return null as unknown as TextIndicator;
  }

  private dialogueReturnHandler(actionName) {
    this.showLinkCurriculumOption = false;
    if (actionName === 'link') {
      this.showLinkBulkCurriculum = true;
    }
  }

  private applyCurriculumToItems() {
    if (this.selectedCurriculumItems.length > 0) {
      const startIndex = this.previewItems.length - this.previewItemsCreatedCount;
      for (let i = startIndex; i < this.previewItems.length; i++) {

        this.selectedCurriculumItems.forEach((link: CurriculumLinkage) => {
          const label = link.progression ?? link.outcomeCode;
          const found = this.previewItems[i].curriculumLinkages.filter((l: string) => l === label);
          if (found.length === 0) {
            this.previewItems[i].curriculumLinkages.push(label);
          }
        });
      }
      this.$store.dispatch('setPreviewItems', this.previewItems);
    }
    this.showLinkBulkCurriculum = false;
  }

  private curriculumItemSelected(itemData: any) {
    if (itemData.value === true) {
      this.selectedCurriculumItems.push(itemData.item);
    } else {
      this.selectedCurriculumItems = this.selectedCurriculumItems.filter((item) => item.id !== itemData.item.id);
    }

    console.log(this.selectedCurriculumItems);

  }

  private curriculumItemAllSelected(items: any) {
    if (items.value === true) {
      this.selectedCurriculumItems = items.items;
    } else {
      this.selectedCurriculumItems = [];
    }
  }

}
