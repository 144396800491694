


















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({

})
export default class HelpCard extends Vue {
  @Prop() public url!: string;
  @Prop() public label!: string;
  @Prop() public extUrl!: string;
}
