import Vue from 'vue';
import VueRouter, {NavigationGuardNext, Route, RouteConfig} from 'vue-router';
// @ts-ignore
import Welcome from '../views/Welcome';
import store from '@/store/index';
import UserHome from '@/views/UserHome.vue';
import Preview from '@/views/Preview.vue';
import UserHelp from '@/views/UserHelp.vue';
import {ProjectModel} from '@/models/project_model';
import InvalidProject from '@/views/InvalidProject.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
      const code = to.query.code;
      if (!code) {
        if (store.getters.getToken !== undefined && store.getters.getToken !== 'undefined') {
          const result = await store.dispatch('getUserFromToken');
          if (result === false) {
            console.log('failed to get User From Token');
            next();
          }
        } else {
          next();
        }

        if (store.getters.getUid !== null) {
          next('/userHome');
        } else {
          next();
        }
      } else {
        const authenticationResult = await store.dispatch('getUserFromCode', code)
          .then((r) => r)
          .catch((err) => {
            return false;
          });
        if (authenticationResult) {
          next('/userHome');
        } else {
          next();
        }
      }
    }
  },
  {
    path: '/userHome',
    name: 'User Home',
    component: UserHome,
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
      if (!store.getters.getUserModel) {
        next('/');
      } else {
        next();
      }
    }
  },
  {
    path: '/preview/help',
    name: 'preview_help',
    component: UserHelp
  },
  {
    path: '/preview/:id',
    name: 'preview',
    props: true,
    component: Preview,
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
      if (!store.getters.getUid) {
        if (!store.getters.getToken) {
          next('/');
          return;
        }

        const getUserInfo = await store.dispatch('getUserFromToken');

        if (!getUserInfo) {
          next('/');
          return;
        }

        await store.dispatch('listUserProjects');

      }

      const id = parseInt(to.params.id ?? '0', 0);

      if (id === 0) {
        next('/');
        return;
      }

      let userProjects: ProjectModel[] = await store.getters.listPdfProjects;

      if (!userProjects || userProjects.length === 0) {
        await store.dispatch('listUserProjects');
        userProjects = await store.getters.listPdfProjects;
        if (userProjects.length === 0) {
          next('/');
          return;
        }
      }

      const findProjectInUsersProjects = userProjects.findIndex((project: ProjectModel) => project.id === id);

      if (findProjectInUsersProjects < 0) {
        next('/invalidProject');
        return;
      }
      next();
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: 'about' */ '../views/About.vue'),
  },
  {
    path: '/help',
    name: 'help',
    component: UserHelp
  },
  {
    path: '/invalidProject',
    name: 'invalidProject',
    component: InvalidProject
  }

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
