import {DRAW_MODE, DRAW_STATE} from './Draw-Mode';


export interface MenuBarItem {
    drawMode: DRAW_MODE;
    drawState: DRAW_STATE;
    label: string;
    matIcon: string;
    toolTip: string;
}

export const menuBarItems: MenuBarItem[] = [
    {
        drawState: DRAW_STATE.OFF,
        drawMode: DRAW_MODE.SECTION,
        label: 'Lesson Content',
        matIcon: 'notes',
        toolTip: 'Creates a text section on the lesson for you to explain concepts etc'
    }, {
        drawState: DRAW_STATE.OFF,
        drawMode: DRAW_MODE.SHORT,
        label: 'Add Short Answer',
        matIcon: 'title',
        toolTip: 'Creates a question from the text that requires short answer '
    },
    {
        drawState: DRAW_STATE.OFF,
        drawMode: DRAW_MODE.PARAGRAPH,
        label: 'Add Long Answer',
        matIcon: 'view_headline',
        toolTip: 'Creates a question from the text that requires a long form answer'
    },
    {
        drawState: DRAW_STATE.OFF,
        drawMode: DRAW_MODE.MULTIPLE_CHOICE,
        label: 'Multiple Choice Answer',
        matIcon: 'check_box',
        toolTip: 'Creates a question from the text that allows multiple options to be selected in the answer'
    },
    {
        drawState: DRAW_STATE.OFF,
        drawMode: DRAW_MODE.CHECKBOX,
        label: 'Single Choice Answer',
        matIcon: 'radio_button_checked',
        toolTip: 'Creates a question from the text that allows for a single item to be selected in the answer'
    },
    // {
    //     drawState: DRAW_STATE.OFF,
    //     drawMode: DRAW_MODE.SELECT,
    //     label: 'Dropdown',
    //     matIcon: 'arrow_drop_down_circle',
    //     toolTip: 'Creates a question that provides a drop down for an answer'
    // },
    {
        drawState: DRAW_STATE.OFF,
        drawMode: DRAW_MODE.DATE,
        label: 'Add Date Answer',
        matIcon: 'calendar_today',
        toolTip: 'Creates a question from the text that requires a Date answer'
    },
    // {
    //     drawState: DRAW_STATE.OFF,
    //     drawMode: DRAW_MODE.TIME,
    //     label: 'Time',
    //     matIcon: 'access_time',
    //     toolTip: 'Creates a question that requires a Time for an Answer'
    // },
    // {
    //     drawState: DRAW_STATE.OFF,
    //     drawMode: DRAW_MODE.DATE_TIME,
    //     label: 'Date/Time',
    //     matIcon: 'date_range',
    //     toolTip: 'Creates a question that Requires both a Date and Time in the Answer'
    // },
    // {
    //     drawState: DRAW_STATE.OFF,
    //     drawMode: DRAW_MODE.SCALE,
    //     label: 'Range',
    //     matIcon: 'linear_scale',
    //     toolTip: 'Creates a Range(Scale) type question'
    // },
    {
        drawState: DRAW_STATE.OFF,
        drawMode: DRAW_MODE.IMAGE,
        label: 'Capture Image',
        matIcon: 'insert_photo',
        toolTip: 'Captures an Image and adds it to the form'
    },
    {
        drawState: DRAW_STATE.OFF,
        drawMode: DRAW_MODE.CUSTOM,
        label: 'Add Blank Question',
        matIcon: 'note_add',
        toolTip: 'Creates a blank question on the form that does not require you to select any text from the document'
    },
    {
        drawState: DRAW_STATE.OFF,
        drawMode: DRAW_MODE.YOUTUBE,
        label: 'Add YouTube',
        matIcon: 'ondemand_video',
        toolTip: 'Adds a Youtube Video to the Form'
    }
];

export class MenuBarItemHelper {

    public static getMenuItemForDrawMode(drawMode: DRAW_MODE): MenuBarItem {
        const menuItem: MenuBarItem = menuBarItems.find((item) => item.drawMode === drawMode) as unknown as MenuBarItem;
        return menuItem;
    }
}
