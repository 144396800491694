




















import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {DRAW_MODE} from '@/drawing/Draw-Mode';

@Component({
  computed: {
    ...mapGetters({
      currentDrawMode: 'getDrawMode',
      currentDrawState: 'getDrawState',
    })
  },

})
export default class ToolbarButton extends Vue {

  @Prop() private icon!: string;
  @Prop() private label!: string;
  @Prop() private drawMode!: string;
  @Prop() private toolTip!: string;
  private currentDrawMode!: DRAW_MODE;

  @Emit('setMode')
  private setMode() {
    return this.drawMode;
  }

  private get buttonClass(): string {

    let returnString = 'defaultBtn';

    if (this.drawMode && this.currentDrawMode && this.currentDrawMode.toString() === this.drawMode.toString()) {
      returnString = 'highlightedBtn';
    }
    return returnString;
  }

}
