






















import {Component, Emit, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';

@Component({
    computed: {
        ...mapGetters({
            progressionIndicators: 'getFilteredProgressionIdentifier'
        })
    }
})
export default class ProgressionIdentifiers extends Vue {

    private progressionIndicators!: ProgressionIdentifiers[];
    private progressionIndicator: string = '';

    private get indicators(): ProgressionIdentifiers[] {
        return this.progressionIndicators;
    }

    @Emit('selectedProgressionIndicator')
    private select(indicator: string) {
        this.progressionIndicator = indicator;
    }

}

