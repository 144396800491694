























import {Component, Emit, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {Stage} from '../../models/project_model';

@Component({
    computed: {
        ...mapGetters({
            stages: 'getStages'
        })
    }
})
export default class Stages extends Vue {

    private stages!: Stage[];
    private selectedStage: string = '';

    @Emit('onSelect')
    private onSelect(stage: string) {
        this.selectedStage = stage;
    }

}

