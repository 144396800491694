import {
    LearningArea,
    Outcome,
    OutcomeRequest,
    ProgressionIdentifier,
    ProgressionIdentifierRequest,
    ProgressionSubElement,
    Stage
} from '@/models/project_model';
import {RestAPI} from '@/rest_api/rest_api';


export default {
    state: {
        learningAreas: [] as LearningArea[],
        stages: [] as Stage[],
        outcomes: [] as Outcome[],
        subElements: [] as ProgressionSubElement[],
        filteredProgressionIdentifiers: [] as ProgressionIdentifier[]
    },

    getters: {
        getLearningAreas: (state) => {
            return state.learningAreas;
        },

        getStages: (state) => {
            return state.stages;
        },

        getOutcomes: (state) => {
            return state.outcomes;
        },

        getSubElements: (state) => {
            const subs = state.subElements;
            if (subs.length === 1 && subs[0].subElementIdentifier === null) {
                return [];
            }
            return state.subElements;
        },

        getFilteredProgressionIdentifier: (state) => {
            return state.filteredProgressionIdentifiers;
        },

    },

    mutations: {
        setLearningAreas(state, learningAreas: LearningArea[]) {
            state.learningAreas = learningAreas;
        },

        setStages(state, stages: Stage[]) {
            state.stages = stages;
        },

        setOutcomes(state, outcomes: Outcome[]) {
            state.outcomes = outcomes;
        },

        setSubElements(state, subElements: ProgressionSubElement[]) {
            if (subElements.length === 1 && subElements[0].subElementIdentifier === null) {
                state.subElements = [];
            } else {
                state.subElements = subElements;
            }

        },

        setFilteredProgressionIdentifiers(state, progressionIdentifiers: ProgressionIdentifier[]) {
            state.filteredProgressionIdentifiers = progressionIdentifiers;
        }
    },

    actions: {
        async loadLearningAreas({commit}) {
            const result = await RestAPI.getLearningAreas();
            if (result.success) {
                commit('setLearningAreas', result.data);
            }
        },

        async loadStages({commit}) {
            const result = await RestAPI.getStages();
            if (result.success) {
                commit('setStages', result.data);
            }
        },

        async searchOutcomes({commit}, request: OutcomeRequest) {
            const result = await RestAPI.getOutcomes(request.learningArea, request.stage);
            if (result.success) {
                commit('setOutcomes', result.data as Outcome[]);
            } else {
                console.log(result.message);
            }
        },

        async searchSubElements({commit}, outcomeCode: string) {
            const result = await RestAPI.getSubElements(outcomeCode);
            if (result.success) {
                commit('setSubElements', result.data.data as ProgressionSubElement[]);
            } else {
                console.log(result.message);
            }
        },

        async searchProgressionIdentifiers({commit}, request: ProgressionIdentifierRequest) {
            const result = await RestAPI.getFilteredProgInd(request.outcomeCode, request.subElement);
            if (result.success) {
                commit('setFilteredProgressionIdentifiers', result.data);
            } else {
                console.log(result.message);
            }
        }
    }

};
