




























import {Component, Emit, Prop, Ref, Vue, Watch} from 'vue-property-decorator';

@Component({})
export default class ProjectCard extends Vue {
  @Prop() public id!: number;
  @Prop() public imgSrc!: string;
  @Prop() public title!: string;
  @Prop() public projectDate!: string;
  @Prop() public link!: string;
  @Prop() public selected!: boolean;
  @Ref('selectToggle') private selectToggle!: HTMLInputElement;
  private active: boolean = false;

  @Watch('selected')
  private isSelected() {
    return this.selected;
  }

  @Emit('selectProject')
  private selectProject() {
    this.$store.dispatch('setActiveProject', this.id);
  }

  @Emit('navigateToProject')
  private navigateToProject() {
    return this.id;
  }

}

