













import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'InvalidProject'
})

export default class InvalidProject extends Vue {

}
