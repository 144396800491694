import store from '../store/index';
import {UrlMethods} from '@/api/UrlMethods';
import {ProjectModel} from '@/models/project_model';
import {RestAPI} from '@/rest_api/rest_api';

export class PdfManagement {

    private pdfJsLib;
    private readonly currentPDFPageNumber: number;
    private readonly scale: number;
    private totalPages;
    private thumbs: any[] = [];
    private pdf;
    private page;
    private canvas: HTMLCanvasElement | undefined;
    private projectModel: ProjectModel = store.getters.getPdfProject;
    private pageImageDataUrl: string = '';

    constructor() {

        this.pdfJsLib = window['pdfjs-dist/build/pdf'];
        this.pdfJsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
        this.currentPDFPageNumber = 1;
        this.scale = 1;
        this.totalPages = 0;


    }

    public async loadPDF(canvas: HTMLCanvasElement): Promise<boolean> {
        this.projectModel = store.getters.getPdfProject;
        this.canvas = canvas;
        const url = UrlMethods.getServerURL('/pdf/' + store.getters.getPdfProject.id);
        const loadingTask = this.pdfJsLib.getDocument(url);
        return await loadingTask.promise.then(async (pdf) => {
                this.pdf = pdf;
                this.totalPages = this.pdf.numPages;
                this.projectModel.currentPageAnalyzing = 1;
                this.projectModel.totalPages = this.pdf.numPages;
                await store.dispatch('updateProject', this.projectModel);
                return await this._setPage()
                    .then(() => true)
                    .catch((err) => {
                        console.log(err);
                        return false;
                    });
            }, ((reason) => {
                console.error('Error Loading PDF', reason);
                return false;
            })
        );

    }

    public async addAll() {

        this.projectModel = store.getters.getPdfProject;

        for (let i = 1; i <= this.totalPages; i++) {
            this.projectModel.currentPageAnalyzing = i;
            await store.dispatch('updateProject', this.projectModel);
            await this._setPage(i);
            await RestAPI.uploadPageImage(this.pageImageDataUrl, i, this.projectModel);
            this.thumbs.push({
                data: this.pageImageDataUrl,
                use: true,
                page: i
            });
        }
        await this._setPage();
        await store.dispatch('updateProject', this.projectModel);
    }

    private async _setPage(pageNumber?: number) {
        if (!pageNumber) {
            pageNumber = this.currentPDFPageNumber;
        }
        await this.pdf.getPage(pageNumber).then(async (page) => {
            this.page = page;
            await this.renderPage();
        }).catch((err) => console.error(err));
    }

    private async renderPage(): Promise<void> {
        const scale = this.scale;
        const viewport = this.page.getViewport({scale});
        if (!this.canvas) {
            return;
        }
        const context = this.canvas.getContext('2d');

        this.canvas.height = viewport.height;
        this.canvas.width = viewport.width;
        this.projectModel.height = this.canvas.height;
        this.projectModel.width = this.canvas.width;
        this.projectModel.landscape = this.projectModel.width > this.projectModel.height;

        // Render PDF page into canvas context
        const renderContext = {
            canvasContext: context,
            viewport
        };
        const renderTask = this.page.render(renderContext);
        await renderTask.promise.then(() => {
            // @ts-ignore
            this.pageImageDataUrl = this.canvas.toDataURL('image/png');
        }).catch((err) => {
            console.log(err);
        });
    }

}
