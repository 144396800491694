
























import {Component, Emit, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {ProgressionSubElement} from '@/models/project_model';

@Component({
    computed: {
        ...mapGetters({
            subElements: 'getSubElements'
        }),
    }
})
export default class SubElements extends Vue {

    private subElements!: ProgressionSubElement[];
    private subElement: string = '';

    private get listSubElements(): ProgressionSubElement[] {
        return this.subElements;
    }

    @Emit('subElementSelected')
    private select(subElement: string) {
        this.subElement = subElement;
    }

}

