

















































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {ProjectModel} from '../../../models/project_model';
import {Classroom, Topics} from '@/models/user_model';
import {
  ExportGoogleClassroomRequest,
  ExportGoogleFormRequest,
  PreviewItem,
  PublishedForms
} from '@/models/preview_model';
import {DataTable} from '@nswdoe/doe-ui-core';
import ViewPublishedItem from '@/components/preview/publish/ViewPublishedItem.vue';


@Component({
  components: {
    ViewPublishedItem,
    DataTable
  },
  computed: {
    ...mapGetters({
      project: 'getCurrentProject',
      dirty: 'getPreviewDirty',
      classrooms: 'getClassrooms',
      topics: 'getTopics',
      previewItems: 'getPreviewItems',
      uid: 'getUid',
      published: 'getPublishedForms'
    })
  }
})
export default class PreviewPublish extends Vue {
  private project!: ProjectModel;
  private dirty!: boolean;
  private classrooms !: Classroom[];
  private topics!: Topics[];
  private previewItems!: PreviewItem[];
  private uid!: string;
  private published!: PublishedForms[];
  private classroom: Classroom = null as unknown as Classroom;
  private showDetail: boolean = false;
  private itemDetail: PublishedForms = null as unknown as PublishedForms;
  private openPublish: boolean = false;
  private destination = '0';
  private topic = null as unknown as Topics;
  private lessonTitle = '';
  private lessonDescription = '';
  private courseTitle = '';
  private courseDescription = '';
  private useLessonMeta = true;
  private canEditCourseName = false;
  private tabsForPublishing = null;
  private headers: any[] = [
    {text: 'Published', value: 'publishedDate'},
    {text: 'Assignment', value: 'projectName', groupable: true},
    {text: 'Classroom', value: 'className', groupable: true},
    {text: 'Topic', value: 'topicName'},

  ];

  private get publishedItems(): any[] {
    return this.published.map((item: PublishedForms) => {
      const date = new Date(item.publishedDate);
      return {
        publishedDate: date.toLocaleDateString() + ' ' + date.toLocaleTimeString(),
        projectName: item.projectName,
        projectId: item.projectId,
        courseId: item.courseId,
        courseName: item.courseName,
        editUrl: item.editUrl,
        email: item.email,
        formId: item.formId,
        lessonName: item.lessonName,
        publishedUrl: item.publishedUrl,
        sheet: item.sheet,
        sheetUrl: item.sheetUrl,
        topic: item.topic,
        topicName: item.topicName,
        uid: item.uid,
        className: item.className
      };
    });

  }

  private get canPublish(): boolean {
    return !this.dirty;
  }

  private get classes(): Classroom[] {
    return this.classrooms;
  }

  private get classroomDestination(): boolean {
    return this.destination === '1' && this.classes.length > 0;
  }

  private get hasTopics(): boolean {
    return this.topics && this.topics.length > 0;
  }

  private get hasClassroom(): boolean {
    return this.classroom !== null as unknown as Classroom;
  }

  private get canExecutePublish(): boolean {

    if (this.lessonTitle.length < 1) {
      return false;
    }

    if (this.destination === '0') {
      return true;
    }

    if (this.destination === '1' && this.classroom) {

      if (this.courseTitle.length < 1) {
        return false;
      }

      return !(this.hasTopics && !this.topic);


    }

    return false;
  }

  private mounted() {
    this.lessonTitle = this.project.projectName;
    this.lessonDescription = this.project.projectDescription;
  }

  private triggerProjectUpdated(event) {
    this.$store.commit('mutatePreviewDirty', true);
  }

  private toggleUseLessonName(event) {
    console.log(event);
    if (this.useLessonMeta) {
      this.courseTitle = this.project.projectName;
      this.courseDescription = this.project.projectDescription;
      this.canEditCourseName = false;
      return;
    }

    this.courseTitle = '';
    this.courseDescription = '';
    this.canEditCourseName = true;


  }

  private showItemDetails(item) {
    this.itemDetail = item;
    this.showDetail = true;
  }

  private hideItemDetails() {
    this.showDetail = false;
  }

  @Watch('classroom')
  private async setTopics(newValue: Classroom, oldValue: Classroom) {
    await this.$store.dispatch('setTopicsForClassRoom', newValue.id);
  }

  private async showPublish() {
    this.toggleUseLessonName(true);
    await this.$store.dispatch('refreshToken');
    await this.$store.dispatch('setUserClassrooms');
    this.openPublish = true;
  }

  private hidePublish() {
    this.classroom = null as unknown as Classroom;
    this.destination = null as unknown as string;
    this.openPublish = false;
  }

  private async executePublish() {
    if (this.destination === '0') {
      const exportGoogleFormRequest: ExportGoogleFormRequest = {
        previewItems: this.previewItems,
        projectDescription: this.lessonDescription,
        projectId: this.project.id,
        projectName: this.lessonTitle,
        uid: this.uid,
        email: this.$store.getters.getUserModel.email
      } as ExportGoogleFormRequest;

      const result = await this.$store.dispatch('exportGoogleForm', exportGoogleFormRequest);

    } else {

      const exportToGoogleClassroomRequest: ExportGoogleClassroomRequest = {
        previewItems: this.previewItems,
        projectDescription: this.lessonDescription,
        projectId: this.project.id,
        projectName: this.project.projectName,
        uid: this.uid,
        courseDescription: this.courseDescription,
        courseId: this.classroom.id,
        courseTitle: this.courseTitle,
        topicId: this.topic ? this.topic.topicId : '',
        email: this.$store.getters.getUserModel.email,
        topicName: this.topic ? this.topic.name : '',
        className: this.classroom.name,
        lessonName: this.lessonDescription
      };

      const resultClassroom = await this.$store.dispatch('exportGoogleClassroom', exportToGoogleClassroomRequest);

    }

    await this.$store.dispatch('refreshPublishedForms', this.project.id);
    this.hidePublish();
  }


}
