const addTrailingZero = function(val) {
  return val < 10 ? '0'+val : val;
};

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Nov", "Dec"];

const hour12 = (h) => {
  if (h === 0 || h === 12) {
    return 12;
  }

  return (h < 12) ? h : (h - 12);
}

const dateParts = (dateStr) => {
  if (!dateStr) {
    return {};
  }

  const timeZoneSplit = dateStr.split("+");
  let formattedDateStr = dateStr;
  const tz = timeZoneSplit[timeZoneSplit.length-1];
  if (timeZoneSplit.length === 2 && tz.length === 4) {
    // we're missing the : in the timezone which fails in IE11
    const formattedTz = "+" + tz.substr(0,2) + ":" + tz.substring(2);
    formattedDateStr = timeZoneSplit[0] + formattedTz;
  }

  const d = new Date(formattedDateStr);

  return {
    dd: addTrailingZero(d.getDate()),
    month: months[d.getMonth()],
    mm: addTrailingZero(d.getMonth() + 1),
    yyyy: d.getFullYear(),
    hh: addTrailingZero(d.getHours()),
    mins: addTrailingZero(d.getMinutes()),
    ss: addTrailingZero(d.getSeconds()),
    hh12: hour12(d.getHours()),
    ampm: d.getHours() < 12 ? "am" : "pm"
  }
}

export function dateTime(dateStr) {
  if (!dateStr) { return ""; }

  const dp = dateParts(dateStr)

  return `${dp.dd}/${dp.mm}/${dp.yyyy}, ${dp.hh}:${dp.mins}:${dp.ss}`;
}

export function timeDate(dateStr) {
  if (!dateStr) { return ""; }

  const dp = dateParts(dateStr)

  return `${dp.hh12}:${dp.mins}${dp.ampm}, ${dp.dd}/${dp.mm}/${dp.yyyy}`;
}

export function date(dateStr) {
  if (!dateStr) { return ""; }

  const dp = dateParts(dateStr)

  return `${dp.dd}/${dp.mm}/${dp.yyyy}`;
}

export function time(dateStr) {
  if (!dateStr) { return ""; }

  const dp = dateParts(dateStr)

  return `${dp.hh12}:${dp.mins}${dp.ampm}`;
}