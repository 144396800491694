import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

import doeColors from '@/settings/doe-colors';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: doeColors.primary.navy,
        secondary: doeColors.primary.lightblue,
        accent: doeColors.secondary.blue1,
        success: doeColors.secondary.blue2,
        error: doeColors.notification.errorred
      }
    }
  }
});
