var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-end",staticStyle:{"cursor":"grab"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.doFirst}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#79797CFF"}},'v-icon',attrs,false),on),[_vm._v(" vertical_align_top ")])],1)]}}])},[_c('span',[_vm._v("Move to the Top of Lesson Content")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.doLast}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#79797CFF"}},'v-icon',attrs,false),on),[_vm._v("vertical_align_bottom ")])],1)]}}])},[_c('span',[_vm._v("Move to the Bottom of the Lesson Content")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.doDelete}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("delete ")])],1)]}}])},[_c('span',[_vm._v("Delete this Lesson Content item")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }