import projectsApi from 'api/projects';

export default {
  state: {
    projects: [],
    projectProcessingMeta: {
      show: false
    }
  },
  getters: {
    projects: function(state) { return state.projects; },
    projectProcessingMeta: state => state.projectProcessingMeta
  },
  mutations: {
    setProjects(state, projects) {
      state.projects = projects;
    },
    setPageProcessMeta(state, metaData) {
      state.projectProcessingMeta = metaData;
    }
  },
  actions: {
    fetchProjects({commit}) {
      commit('setIsLoading', true);
      return projectsApi.getProjects()
        .then((result) => {
          commit('setProjects', result.Items);
          commit('setIsLoading', false);
        })
        .catch(() => {
          commit('setIsLoading', false);
        });
    },
    createProject({commit}, data) {
      commit('setPageProcessMeta', {show: false});
      commit('setIsLoading', true);
      return projectsApi.createProject(data)
        .then((result) => {
          commit('setIsLoading', false);
          // fake progress bar pusher
          // todo: replace with page processing api
          let pageCounter = 0;
          const totalPages = 5;
          const intervalHandler = setInterval(() => {
            if (pageCounter > totalPages) {
              commit('setPageProcessMeta', {show: false});
              clearInterval(intervalHandler);
              return;
            }

            commit('setPageProcessMeta', {show: true, processedPages: pageCounter, totalPages: totalPages});
            pageCounter++;
          }, 500);
        })
        .catch(() => {
          commit('setIsLoading', false);
        });
    }
  }
};
