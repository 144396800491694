

































import {Component, Emit, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {Outcome} from '@/models/project_model';

@Component({
    computed: {
        ...mapGetters({
            outcomes: 'getOutcomes'
        })
    }
})
export default class Outcomes extends Vue {
    private outcomes!: Outcome[];
    private selectedOutcome: string = '';
    private searchString = '';

    private setSearchString(value) {
        this.searchString = value.toString();
    }

    @Emit('outcomeSelected')
    private select(code) {
        this.selectedOutcome = code;
    }

    get filtered(): Outcome[] {
        if (this.searchString.length === 0) {
            return this.outcomes;
        } else {
            const search = this.searchString.toLowerCase();
            return this.outcomes.filter((o) => {
                const code = o.outcomeCode.toLowerCase();
                const description = o.outcomeDescription.toLowerCase();
                if (code.indexOf(search) >= 0) {
                    return true;
                }
                return description.indexOf(search) >= 0;



            });
        }
    }

}
