import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@babel/polyfill';
import { IeBlocker } from '@nswdoe/doe-ui-core';
import { date } from './filters/date';
import AsyncComputed from 'vue-async-computed';

Vue.config.productionTip = false;

let renderComponent;
let renderProps;

if (IeBlocker.isIe()) {
  renderComponent = IeBlocker;
  renderProps = {
    props: {
      block: true,
      appName: 'PDF to Forms Conversion Tool',
    },
  };
} else {
  renderComponent = App;
  renderProps = {};

  // initialise oauth and other app features
  Vue.config.productionTip = false;

  // Global event bus
  Vue.prototype.$eventHub = new Vue();

  Vue.filter('date', date);
}

Vue.use(AsyncComputed);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(renderComponent, renderProps),
}).$mount('#app');
