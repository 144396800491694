















































































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {Alert, Dialog, Stepper} from '@nswdoe/doe-ui-core';
import {mapGetters} from 'vuex';
import {
  CurriculumLinkage,
  Outcome,
  OutcomeRequest,
  ProgressionIdentifier,
  ProgressionIdentifierRequest,
  ProjectModel
} from '@/models/project_model';
import LearningAreas from '@/components/curriculum/LearningAreas.vue';
import Stages from '@/components/curriculum/Stages.vue';
import Outcomes from '@/components/curriculum/Outcomes.vue';
import SubElements from '@/components/curriculum/SubElement.vue';
import ProgressionIdentifiers from '@/components/curriculum/ProgressionIdentifiers.vue';
import CardContent from '@/components/ui/CardContent.vue';
import OutcomeOnly from '@/components/curriculum/OutcomeOnly.vue';


@Component({
  components: {
    OutcomeOnly,
    CardContent,
    ProgressionIdentifiers, Outcomes, Stages, Alert, Dialog, Stepper, LearningAreas, SubElements
  },
  computed: {
    ...mapGetters({
      project: 'getPdfProject',
      learningAreas: 'getLearningAreas',
      stages: 'getStages',
      outcomes: 'getOutcomes',
      progressions: 'getFilteredProgressionIdentifier',
      previewProjectLinkages: 'getCurrentProjectLinkages',
      previewProject: 'getCurrentProject'
    })
  }
})

export default class Curriculum extends Vue {

  @Prop({default: true}) public isProject!: boolean;
  private COMPLETE = 'complete';
  private ERROR = 'invalid';
  private steps: any[] = [
    {name: 'Learning Area'},
    {name: 'Stage'},
    {name: 'Outcome'},
    {name: 'Progression Element'},
    {name: 'Progression Indicator'}

  ];
  private previewProjectLinkages!: CurriculumLinkage[];
  private previewProject!: ProjectModel;
  private project!: ProjectModel;
  private outcomes!: Outcome[];
  private progressions!: ProgressionIdentifier[];
  private selectedLearningArea: string = '';
  private selectedStage: string = '';
  private selectedOutcome: string = '';
  private selectedProgression: string = '';
  private selectedSubElement: string = '';
  private currentStep = 1;
  private errorLearningArea = false;
  private errorStage = false;
  private errorOutcome = false;
  private errorSubElement = false;
  private canSaveOutcomeOnly = false;
  private canSave = false;


  private selectingLearningArea(learningArea: string) {
    this.selectedLearningArea = learningArea;
    this.steps[0].status = this.COMPLETE;
    this.errorLearningArea = false;
    this.currentStep = 2;
    this.selectedOutcome = '';

  }

  private async selectingStage(stage: string) {
    this.selectedStage = stage;
    this.steps[1].status = this.COMPLETE;
    this.errorStage = false;
    const outcomeRequest: OutcomeRequest = {
      learningArea: this.selectedLearningArea,
      stage: this.selectedStage
    };
    this.selectedOutcome = '';
    await this.$store.dispatch('searchOutcomes', outcomeRequest);
    this.selectStep(3);
    this.currentStep = 3;
  }

  private async selectingOutcome(outcomeCode: string) {
    this.selectedOutcome = outcomeCode;
    await this.$store.dispatch('loading', true);
    await this.$store.dispatch('searchSubElements', outcomeCode);
    await this.$store.dispatch('loading', false);
    if (this.$store.getters.getSubElements.length < 1) {
      this.canSaveOutcomeOnly = true;
      this.steps[2].status = this.COMPLETE;
    } else {
      this.selectStep(4);
      this.currentStep = 4;
    }

  }

  private get outcome(): string {
    return this.selectedOutcome;
  }

  private get outcomeDescription(): string {
    if (this.outcome) {
      const oc = this.outcomes.find((o) => o.outcomeCode === this.outcome);
      if (oc) {
        return oc.outcomeDescription;
      }
    }
    return '';
  }

  private get progression(): string {
    return this.selectedProgression;
  }

  private get progressionDescription(): string {
    if (this.progression) {
      const pg = this.progressions.find((p) => p.progressionIdentifier === this.progression);
      if (pg) {
        return pg.progressionDescription;
      }
    }
    return '';
  }

  private async selectingSubElement(subElement: string) {
    this.selectedSubElement = subElement;
    await this.$store.dispatch('loading', true);
    await this.$store.dispatch('searchProgressionIdentifiers', {
      subElement: this.selectedSubElement,
      outcomeCode: this.selectedOutcome
    } as ProgressionIdentifierRequest);
    await this.$store.dispatch('loading', false);
    this.selectStep(5);
    this.currentStep = 5;
  }

  private async selectingProgressionIdentifier(progressionIdentifier: string) {
    this.selectedProgression = progressionIdentifier;
    this.steps[4].status = this.COMPLETE;
    this.canSave = true;
  }

  private mounted() {
    this.selectStep(1);
  }

  private get canCreateLinkage(): boolean {
    return this.canSaveOutcomeOnly || this.canSave;
  }

  private async save(addNew: boolean) {

    const linkage: CurriculumLinkage = {
      projectId: this.isProject ? this.project.id : this.previewProject.id,
      outcomeCode: this.selectedOutcome,
      outcomeDescription: this.outcomeDescription,
    } as CurriculumLinkage;

    if (this.canSaveOutcomeOnly) {
      linkage.progression = null as unknown as string;
      linkage.progressionDescription = null as unknown as string;
    } else {
      linkage.progression = this.progression;
      linkage.progressionDescription = this.progressionDescription;
    }
    await this.$store.dispatch('loading', true);
    await this.$store.dispatch('addProjectLinkage', linkage);
    if (!this.isProject) {
      await this.$store.dispatch('addLinkage', linkage);
    }

    await this.$store.dispatch('loading', false);

    if (addNew) {
      this.reset();
    } else {
      this.linked(true);
    }

  }

  private cancel() {
    if (this.isProject) {
      this.$store.dispatch('deleteProject', this.project.id);
    }
    this.reset();
    this.linked(false);
  }

  private skip() {
    this.reset();
    this.linked(true);
  }

  @Emit('linked')
  private linked(result: boolean) {
    return result;
  }

  private reset() {
    this.selectedProgression = '';
    this.selectedOutcome = '';
    this.selectedLearningArea = '';
    this.selectedStage = '';
    this.selectStep(1);
  }


  private selectStep(event) {

    if (event < 5) {
      this.selectedProgression = '';
      this.canSave = false;
    }

    if (event < 4) {
      this.selectedSubElement = '';
    }

    if (event < 3) {
      this.selectedOutcome = '';
      this.canSaveOutcomeOnly = false;
    }

    if (event < 2) {
      this.selectedStage = '';
      this.canSaveOutcomeOnly = false;
    }

    if (event === 1) {
      this.selectedLearningArea = '';
      this.canSaveOutcomeOnly = false;
    }

    if (event > 4 && this.selectedSubElement === '') {
      this.steps[3].status = this.ERROR;
      this.errorSubElement = true;
    } else if (event > 4 && this.selectedSubElement.length > 1) {
      this.steps[3].status = this.COMPLETE;
      this.errorSubElement = false;
    } else {
      this.steps[3].status = null;
      this.errorSubElement = false;
    }

    if (event > 3 && this.selectedOutcome === '') {
      this.steps[2].status = this.ERROR;
      this.errorOutcome = true;
    } else if (event > 3 && this.selectedOutcome.length > 1) {
      this.steps[2].status = this.COMPLETE;
      this.errorOutcome = false;
    } else {
      this.steps[2].status = null;
      this.errorOutcome = false;
    }

    if (event > 2 && this.selectedStage === '') {
      this.steps[1].status = this.ERROR;
      this.errorStage = true;
    } else if (event > 2 && this.selectedStage.length > 1) {
      this.steps[1].status = this.COMPLETE;
      this.errorStage = false;
    } else {
      this.steps[1].status = null;
      this.errorStage = false;
    }

    if (event > 1 && this.selectedLearningArea === '') {
      this.steps[0].status = this.ERROR;
      this.errorLearningArea = true;
    } else if (event > 1 && this.selectedLearningArea.length > 1) {
      this.steps[0].status = this.COMPLETE;
      this.errorLearningArea = false;
    } else {
      this.steps[0].status = null;
      this.errorLearningArea = false;
    }

    if (this.selectedLearningArea === '' && this.selectedStage === '') {
      this.steps[2].status = 'disabled';
    }

    if (this.selectedOutcome === '') {
      this.steps[3].status = 'disabled';
      this.steps[4].status = 'disabled';

    } else if (this.selectedSubElement === '') {
      this.steps[3].status = null;
      this.steps[4].status = 'disabled';
    } else {
      this.steps[3].steps = this.COMPLETE;
      this.steps[4].status = null;
    }


    this.currentStep = event;
  }


}
