import { render, staticRenderFns } from "./ProjectCard.vue?vue&type=template&id=3a2b86ec&scoped=true&"
import script from "./ProjectCard.vue?vue&type=script&lang=ts&"
export * from "./ProjectCard.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectCard.vue?vue&type=style&index=0&id=3a2b86ec&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a2b86ec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VIcon})
