






















































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {Choice, FactoryPreviewItems, PreviewItem} from '@/models/preview_model';
import {DRAW_MODE} from '@/drawing/Draw-Mode';

@Component({
  computed: {
    ...mapGetters({
      project: 'getCurrentProject',
      previewItems: 'getPreviewItems',
    })
  }
})

export default class PreviewItemChoices extends Vue {
  @Prop() public previewItem!: PreviewItem;
  @Prop() public index!: number;
  private radioModel = null;


  private get isMultipleChoice(): boolean {
    if (this.previewItem?.type?.drawMode) {
      return this.previewItem.type.drawMode === DRAW_MODE.MULTIPLE_CHOICE;
    }
    return false;
  }

  private async saveCorrectItem(choice: Choice, index: number) {

    this.previewItem.options[index].isCorrect = !this.previewItem.options[index].isCorrect;

    if (this.previewItem.options[index].isCorrect && this.previewItem?.type?.drawMode !== DRAW_MODE.MULTIPLE_CHOICE) {
      for (let i = 0; i < this.previewItem.options.length; i++) {
        if (i !== index) {
          this.previewItem.options[i].isCorrect = false;
        }
      }
    }

    this.notifyUpdate();


  }

  private async saveTextItem(choice: Choice, index: number) {
    this.previewItem.options[index].text = choice.text;
    this.notifyUpdate();
  }

  private async addOption() {
    const newOption = FactoryPreviewItems.instanceChoice();
    this.previewItem.options.push(newOption);
    this.notifyUpdate();
  }

  private async deleteItem(index) {
    this.previewItem.options.splice(index, 1);
    this.notifyUpdate();
  }

  @Emit('updated')
  private notifyUpdate(): PreviewItem {
    return this.previewItem;
  }

}


