export interface CreateProjectModel {
    file: any;
    projectName: string;
    projectDescription: string;
    markQuestionsRequiredByDefault: boolean;
    enableMarking: boolean;
    uid: string;
    userEmail: string;
    defaultMarks: number;
}

export interface CreateProjectResponse {
    bucket: string;
    etag: string;
    id: number;
    location: string;
    name: string;
    path: string;
}

export interface ProjectModel {
    id: number;
    bucket: string;
    defaultMarks: number;
    defaultRequired: boolean;
    etag: string;
    fileName: string;
    height: number;
    landscape: boolean;
    location: string;
    marking: boolean;
    path: string;
    projectDescription: string;
    projectName: string;
    totalPages: number;
    uid: string;
    userEmail: string;
    width: number;
    currentPageAnalyzing: number;
    selected?: boolean;
    mainIdea: string;
    mainIdeaRtf: any;
    objectives: string;
    objectivesRtf: any;
    content: string;
    contentRtf: any;
    teachersNotes: string;
    teachersNotesRtf: any;
    language: string;
    languageRtf: any;
    assessment: string;
    assessmentRtf: any;
    sampleActivities: string;
    sampleActivitiesRtf: any;
    resources: string;
    resourcesRtf: any;
    created: string;
}

export interface CurriculumLinkage {
    projectId: number;
    outcomeCode: string;
    outcomeDescription: string;
    progression?: string;
    progressionDescription?: string;
    id?: number;
}

export interface LearningArea {
    learningArea: string;
}

export interface Stage {
    stage: string;
}

export interface Outcome {
    outcomeCode: string;
    outcomeDescription: string;
    stage: string;
    stageCode: string;
    code: string;
    codeDescription: string;
    learningArea: string;
    outcomeName: string;
}

export interface OutcomeRequest {
    stage: string;
    learningArea: string;
}

export interface ProgressionSubElement {
    subElementIdentifier: string;
    subElementDescription: string;
}

export interface ProgressionIdentifier {
    progressionIdentifier: string;
    progressionDescription: string;
}

export interface ProgressionIdentifierRequest {
    outcomeCode: string;
    subElement: string;
}

export class ProjectModelFactory {
    public static factoryInstance() {
        return {
            userEmail: '',
            projectDescription: '',
            uid: '',
            bucket: '',
            defaultMarks: 0,
            defaultRequired: false,
            etag: '',
            fileName: '',
            height: 0,
            id: 0,
            landscape: false,
            location: '',
            marking: false,
            path: '',
            totalPages: 0,
            width: 0,
            projectName: '',
            selected: false,
            assessment: '',
            assessmentRtf: null,
            content: '',
            contentRtf: null,
            language: '',
            languageRtf: null,
            mainIdea: '',
            mainIdeaRtf: null,
            objectives: '',
            objectivesRtf: null,
            resources: '',
            resourcesRtf: null,
            sampleActivities: '',
            sampleActivitiesRtf: null,
            teachersNotes: '',
            teachersNotesRtf: null,
            currentPageAnalyzing: 0,
            created: new Date().toISOString()
        } as ProjectModel;
    }
}
