






import {Component, Prop, Vue} from 'vue-property-decorator';
import {PreviewItem} from '@/models/preview_model';

@Component({})
export default class DrawModeIcon extends Vue {
  @Prop() public item!: PreviewItem;

}

