import {CreateProjectModel, CreateProjectResponse, ProjectModel, ProjectModelFactory} from '@/models/project_model';


export class ProjectMethods {

    public static createFormData(data: CreateProjectModel) {

        const formData = new FormData();
        formData.append('uid', data.uid);
        formData.append('projectDescription', data.projectDescription);
        formData.append('marking', data.toString());
        formData.append('defaultMarks', data.enableMarking.toString());
        formData.append('pdf', data.file);
        formData.append('projectName', data.projectName);
        formData.append('userEmail', data.userEmail);
        formData.append('defaultRequired', data.markQuestionsRequiredByDefault.toString());

        return formData;

    }

    public static createProjectFromResponse(
        response: CreateProjectResponse,
        request: CreateProjectModel): ProjectModel {

        const projectModel: ProjectModel = ProjectModelFactory.factoryInstance();

        projectModel.path = response.path;
        projectModel.marking = request.enableMarking;
        projectModel.location = response.location;
        projectModel.id = response.id;
        projectModel.fileName = response.name;
        projectModel.etag = response.etag;
        projectModel.defaultRequired = request.markQuestionsRequiredByDefault;
        projectModel.bucket = response.bucket;
        projectModel.defaultMarks = request.defaultMarks;
        projectModel.uid = request.uid;
        projectModel.userEmail = request.userEmail;
        projectModel.projectDescription = request.projectDescription;
        projectModel.projectName = request.projectName;
        projectModel.currentPageAnalyzing = 1;

        return projectModel;

    }

}
