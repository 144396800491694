






































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import ToolBar from '@/components/ui/ToolBar.vue';
import {CurriculumLinkage, ProjectModel} from '@/models/project_model';
import {UrlMethods} from '@/api/UrlMethods';
import PreviewPageImageCards from '@/components/preview/PreviewPageImageCards.vue';
import DrawCanvas from '@/components/preview/DrawCanvas.vue';
import {Choice, PreviewItem, UpdateProjectRequest} from '@/models/preview_model';
import PreviewCard from '@/components/preview/PreviewCard.vue';
import PreviewCurriculum from '@/components/preview/curriculum_linkages/PreviewCurriculum.vue';
import {DRAW_MODE} from '@/drawing/Draw-Mode';
import LessonPlan from '@/components/preview/lesson_plan/LessonPlan.vue';
import PreviewPublish from '@/components/preview/publish/PreviewPublish.vue';
import draggable from 'vuedraggable';

@Component({
  components: {
    PreviewPublish,
    LessonPlan,
    PreviewCurriculum,
    PreviewCard, DrawCanvas, PreviewPageImageCards, ToolBar, draggable
  },
  computed: {
    ...mapGetters({
      projects: 'listPdfProjects',
      project: 'getCurrentProject',
      linkages: 'getCurrentProjectLinkages',
      previewItems: 'getPreviewItems',
      dirty: 'getPreviewDirty'
    })
  }
})
export default class Preview extends Vue {
  @Prop() public id!: string;
  private projects!: ProjectModel[];
  private project!: ProjectModel;
  private linkages!: CurriculumLinkage[];
  private imageUrls: string[] = [];
  private loaded: boolean = false;
  private tab = null;
  private currentPage: number = 0;
  private previewItems!: PreviewItem[];
  private showDeleteConfirm = false;
  private deleteIndex!: number;
  private dirty!: boolean;


  private get userProjects(): ProjectModel[] {
    return this.projects as ProjectModel[];
  }

  private get previewItemList(): PreviewItem[] {
    return this.previewItems;
  }

  private set previewItemList(previewItems: PreviewItem[]) {
    this.$store.dispatch('setPreviewItems', previewItems);
  }

  private get canSave(): boolean {

    if (!this.dirty) {
      return false;
    }

    for (const previewItem of this.previewItems) {
      if (this.hasChoices(previewItem)) {
        if (previewItem.options.length === 0) {
          return false;
        }
        const hasValidChoices = this.hasValidChoices(previewItem.options);
        if (!hasValidChoices) {
          return false;
        }
      }
    }
    return true;
  }

  private getProjectPageImage(page: number): string {
    return UrlMethods.getServerURL('/projectPageImage/' + this.project.uid + '/' + this.project.id + '/' + page);
  }

  private confirmDelete(index: number) {
    this.deleteIndex = index;
    this.showDeleteConfirm = true;
  }

  private deleteSelectedPreviewItem() {
    this.$store.dispatch('deletePreviewItem', this.deleteIndex);
    this.showDeleteConfirm = false;
    this.deleteIndex = null as unknown as number;
  }

  private pageSelected(page: number) {
    this.currentPage = page;
    this.$store.dispatch('setCurrentPage', page);
  }

  private isSelected(page: number) {
    return page === this.currentPage;
  }

  private async mounted() {
    const id = parseInt(this.id, 0);
    const project = this.userProjects.find((p) => p.id === id) as ProjectModel;
    await this.$store.dispatch('setCurrentProject', project);
    if (project) {
      this.imageUrls = [];
      for (let i: number = 1; i <= project.totalPages; i++) {
        const url: string = this.getProjectPageImage(i);
        this.imageUrls.push(url);
      }
      await this.$store.dispatch('setImageUrls', this.imageUrls);
    }
    this.loaded = true;
  }

  private hasValidChoices(choices: Choice[]) {
    let hasValidChoicesResult = false;
    for (const choice of choices) {
      if (choice.text === '') {
        return false;
      }
      if (choice.isCorrect) {
        hasValidChoicesResult = true;
      }
    }
    return hasValidChoicesResult;
  }

  private hasChoices(previewItem: PreviewItem): boolean {
    return previewItem.type.drawMode === DRAW_MODE.MULTIPLE_CHOICE ||
        previewItem.type.drawMode === DRAW_MODE.SELECT ||
        previewItem.type.drawMode === DRAW_MODE.CHECKBOX;

  }

  private async savePreview() {
    if (!this.canSave) {
      return;
    }

    const updateRequest: UpdateProjectRequest = {
      previewItems: this.previewItems,
      projectModel: this.project
    };
    await this.$store.dispatch('saveCurrentProject', updateRequest);
    // await this.$router.push('/userHome');
  }

  private onEnd(event) {
    event.preventDefault();
    if (event.oldIndex !== event.newIndex) {
      this.$store.commit('mutatePreviewDirty', true);
    }

  }


}

