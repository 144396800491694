





































































































































































































































































































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {Choice, menuBarItemDropDownMembers, PreviewItem, SavePreviewItemRequest} from '@/models/preview_model';
import {DRAW_MODE} from '@/drawing/Draw-Mode';
import {UrlMethods} from '@/api/UrlMethods';
import {CurriculumLinkage, ProjectModel} from '@/models/project_model';
import DrawModeIcon from '@/components/preview/DrawModeIcon.vue';
import PreviewItemOrderDelete from '@/components/preview/PreviewItemOrderDelete.vue';
import {MenuBarItemHelper} from '@/drawing/MenuBarItems';
import PreviewItemChoices from '@/components/preview/PreviewItemChoices.vue';
import {Alert} from '@nswdoe/doe-ui-core';

@Component({
  components: {PreviewItemChoices, PreviewItemOrderDelete, DrawModeIcon, Alert},
  computed: {
    ...mapGetters({
      project: 'getCurrentProject',
      projectLinkages: 'getCurrentProjectLinkages'
    })
  }
})
export default class PreviewCard extends Vue {
  @Prop() public previewItem!: PreviewItem;
  @Prop() public index!: number;
  private project!: ProjectModel;
  private restorePreviewItem!: PreviewItem;
  private projectLinkages!: CurriculumLinkage[];
  private drawModeSelectors = menuBarItemDropDownMembers;
  private drawMode: DRAW_MODE = this.previewItem.type.drawMode;
  private showCantSave: boolean = false;
  private showCantSaveMessage: string = '';

  get youtubeUrl(): string {
    if (this.previewItem.url) {
      const youTubeParts = this.previewItem.url.split('=');
      if (youTubeParts.length > 0) {
        return 'https://www.youtube.com/embed/' + youTubeParts[1] + '?controls=0';
      }
    }
    return '';
  }

  private get isImage(): boolean {
    return this.previewItem.type.drawMode === DRAW_MODE.IMAGE;
  }

  private get imageUrl(): string {
    if (!this.project) {
      return '';
    }
    const url = '/formImage/' + this.project.uid + '/' + this.project.id + '/' + this.previewItem.imageFileName;
    return 'background-image: url(' + UrlMethods.getServerURL(url) + ')';
  }

  private get isRange(): boolean {
    return this.previewItem.type.drawMode === DRAW_MODE.SCALE;
  }

  private get isYouTube(): boolean {
    return this.previewItem.type.drawMode === DRAW_MODE.YOUTUBE;
  }

  private get isHeading(): boolean {
    return this.previewItem.type.drawMode === DRAW_MODE.SECTION;
  }

  private get linkageItems(): any[] {
    const items: string[] = [];
    this.projectLinkages.map((l) => {
      const label = l.progression ? l.progression : l.outcomeCode;
      items.push(label);
    });
    return items;
  }

  private get hasCurriculumLinks(): boolean {
    if (this.previewItem?.curriculumLinkages) {
      if (this.previewItem.curriculumLinkages.length > 0) {
        return true;
      }
    }
    return false;
  }

  private get hasChoices(): boolean {
    return this.previewItem.type.drawMode === DRAW_MODE.MULTIPLE_CHOICE ||
        this.previewItem.type.drawMode === DRAW_MODE.SELECT ||
        this.previewItem.type.drawMode === DRAW_MODE.CHECKBOX;

  }

  private get questionText(): string {
    if (this.previewItem?.type?.drawMode === DRAW_MODE.IMAGE) {
      return 'Descriptive Text';
    }

    if (this.previewItem?.type?.drawMode === DRAW_MODE.SECTION) {
      return 'Lesson Content';
    }

    return 'Question Text';

  }

  @Emit('deleteItem')
  public showDeleteItem(index: number) {
    return index;
  }

  private mounted() {
    this.restorePreviewItem = {...this.previewItem};
  }

  private async toggleEdit() {
    this.restorePreviewItem = {...this.previewItem};
    await this.$store.dispatch('setPreviewItemToEdit', this.index);
  }

  private async savePreviewItem() {

    this.showCantSave = false;

    if (this.hasChoices) {
      const canAdvanceText = this.previewItem.options.findIndex((p) => p.text === '');
      const canAdvanceCorrectAnswer = this.previewItem.options.findIndex((p) => p.isCorrect);
      if (canAdvanceText > -1) {
        this.showCantSave = true;
        this.showCantSaveMessage = 'Error: All options must have text!';
        return;
      } else if (this.previewItem.options.length === 0) {
        this.showCantSave = true;
        this.showCantSaveMessage = 'This question requires options!';
        return;
      } else if (canAdvanceCorrectAnswer < 0) {
        this.showCantSave = true;
        this.showCantSaveMessage = 'This question requires a correct answer!';
        return;
      }
    }

    if (!this.showCantSave) {
      this.previewItem.edit = false;
      this.restorePreviewItem = {...this.previewItem};
      const request: SavePreviewItemRequest = {
        index: this.index,
        previewItem: this.previewItem
      };
      await this.$store.dispatch('savePreviewItem', request);
    }

  }

  private async cancelEditing() {

    this.restorePreviewItem.edit = false;
    const request: SavePreviewItemRequest = {
      index: this.index,
      previewItem: this.restorePreviewItem
    };

    await this.$store.dispatch('savePreviewItem', request);

  }

  private async setNewDrawMode() {

    if (this.hasChoices) {
      const correctAnswers: Choice[] = this.previewItem.options.filter(o => o.isCorrect);
      if (correctAnswers.length > 1) {
        this.previewItem.options.forEach((choice: Choice) => choice.isCorrect = false);
      }
    }

    this.previewItem.type = MenuBarItemHelper.getMenuItemForDrawMode(this.drawMode);


  }

  private updatePreviewItem(previewItem: PreviewItem) {
    this.previewItem.options = previewItem.options;
    this.showCantSave = false;
  }


}
