import {ApiCallResult, RestAPI} from '@/rest_api/rest_api';
import {
  CreateProjectModel,
  CreateProjectResponse,
  CurriculumLinkage,
  ProjectModel,
  ProjectModelFactory
} from '@/models/project_model';
import {ProjectMethods} from '@/business/projects/projects_methods';
import {Classroom, Topics} from '@/models/user_model';


export default {

  state: {
    pdfProject: ProjectModelFactory.factoryInstance() as ProjectModel,
    linkages: [] as CurriculumLinkage[],
    pdfProjects: [] as ProjectModel[],
    selectedProject: null as unknown as number,
    classrooms: [] as Classroom[],
    topics: [] as Topics[]
  },

  getters: {

    getPdfProject: (state): ProjectModel => {
      return state.pdfProject;
    },

    getCurriculumLinkage: (state): CurriculumLinkage[] => {
      return state.linkages;
    },

    listPdfProjects: (state): ProjectModel[] => {
      return state.pdfProjects;
    },

    getSelectedProject: (state): number => {
      return state.selectedProject;
    },

    getClassrooms: (state): Classroom[] => {
      return state.classrooms;
    },

    getTopics: (state): Topics[] => {
      return state.topics;
    }

  },

  mutations: {

    updateProjectFromNew: (state, project: ProjectModel) => {
      state.pdfProject = project;
    },

    saveProject: (state, project: ProjectModel) => {
      state.pdfProject = project;
    },

    addLinkage: (state, linkage: CurriculumLinkage) => {
      state.linkages.push(linkage);
      console.log(state.linkages);
    },

    resetLinkages: (state) => {
      state.linkages = [] as CurriculumLinkage[];
    },

    removeProject: (state, id) => {
      const projects = state.pdfProjects;
      state.pdfProjects = projects.filter((p) => p.id !== id);
    },

    setPdfProjects: (state, projects: ProjectModel) => {
      state.pdfProjects = projects;
    },

    mutateSelectedProject: (state, projectId: number) => {
      state.selectedProject = projectId;
    },

    mutateClassrooms: (state, classrooms: Classroom[]) => {
      state.classrooms = classrooms;
    },

    mutateTopics: (state, topics: Topics[]) => {
      state.topics = [];
      state.topics = topics;
    }


  },

  actions: {
    createPdfProject: async (context, createProjectModel: CreateProjectModel): Promise<ApiCallResult> => {
      await context.dispatch('loading', true);
      console.log(createProjectModel);
      const formData = ProjectMethods.createFormData(createProjectModel);

      const apiCallResult: ApiCallResult = await RestAPI.createProject(formData);

      if (apiCallResult.success) {
        const createProjectResponse: CreateProjectResponse = apiCallResult.data;
        const project: ProjectModel =
          ProjectMethods.createProjectFromResponse(createProjectResponse, createProjectModel);
        project.selected = false;
        context.commit('updateProjectFromNew', project);
        await context.dispatch('loading', false);
        return {
          success: true
        };
      } else {
        await context.dispatch('loading', false);
        return {
          success: false,
          message: apiCallResult.message
        };
      }
    },

    updateProject: async (context, projectModel: ProjectModel): Promise<ApiCallResult> => {
      await context.dispatch('loading', true);
      const apiCallResult: ApiCallResult = await RestAPI.updateProject(projectModel);
      if (apiCallResult.success) {

        context.commit('saveProject', projectModel);
      } else {
        console.log('Couldn\'t Update project');
      }
      await context.dispatch('loading', false);
      return apiCallResult;
    },

    addProjectLinkage: async (context, linkage: CurriculumLinkage) => {
      await context.dispatch('loading', true);
      const apiCallResult: ApiCallResult = await RestAPI.addLinkage(linkage);
      linkage.id = apiCallResult.data.id;
      context.commit('addLinkage', linkage);
      await context.dispatch('loading', false);
    },

    listUserProjects: async (context) => {

      const uid = context.getters.getUid;
      if (!uid) {
          context.commit('setPdfProjects', []);
          return;
      }
      const apiCallResult: ApiCallResult = await RestAPI.listProjects(uid);
      if (apiCallResult.success) {
        const projects: ProjectModel = apiCallResult.data.map((p: ProjectModel) => {
          p.selected = false;
          return p as ProjectModel;
        });
        context.commit('setPdfProjects', projects);
      } else {
        console.log(apiCallResult.message);
      }

    },

    resetProject: async (context) => {
      const pdfProject = ProjectModelFactory.factoryInstance() as ProjectModel;
      context.commit('updateProjectFromNew', pdfProject);
      context.commit('resetLinkages');
    },

    deleteProject: async ({commit}, id) => {
      commit('setIsLoading', true);
      const apiCallResult: ApiCallResult = await RestAPI.deleteProjects(id);
      if (apiCallResult.success) {
        commit('removeProject', id);
        commit('mutateSelectedProject', null as unknown as number);
      } else {
        console.error(apiCallResult.message);
      }
      commit('setIsLoading', false);
    },

    setActiveProject: (context, id) => {

      if (context.state.selectedProject === id) {
        context.commit('mutateSelectedProject', null as unknown as number);
      } else {
        context.commit('mutateSelectedProject', id);
      }

      const projects = context.state.pdfProjects as ProjectModel[];
      const updateProject: ProjectModel[] = projects.map((p) => {
        p.selected = id === p.id && !p.selected;
        return p;
      }) as ProjectModel[];
      context.commit('setPdfProjects', updateProject);
    },

    setUserClassrooms: async (context) => {
      await context.dispatch('loading', true);
      const uid = context.getters.getUid;
      const result: ApiCallResult = await RestAPI.getClassrooms(uid);
      if (result.success) {
        context.commit('mutateClassrooms', []);
        context.commit('mutateClassrooms', result.data as Classroom[]);
      } else {
        console.error(result.message);
      }
      await context.dispatch('loading', false);

    },

    setTopicsForClassRoom: async (context, courseId: string) => {
      await context.dispatch('loading', true);
      const uid = context.getters.getUid;
      context.commit('mutateTopics', []);
      const result: ApiCallResult = await RestAPI.getTopics(uid, courseId);
      if (result.success) {
        context.commit('mutateTopics', result.data as Topics[]);
      } else {
        console.error(result.message);
      }

      await context.dispatch('loading', false);

    }

  }

};
