



















































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {PublishedForms} from '../../../models/preview_model';

@Component({})

export default class ViewPublishedItem extends Vue {

  @Prop() public publishedItem!: PublishedForms;

  @Emit('doClose')
  public doClose() {
    return true;
  }


}
