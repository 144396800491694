

























































































































































import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {ProjectModel} from '@/models/project_model';
import ProjectCard from '@/components/project/ProjectCard.vue';
import {UrlMethods} from '@/api/UrlMethods';
import NewProjectCard from '@/components/project/NewProjectCard.vue';
import CopyToFriend from '@/components/project/CopyToFriend.vue';

@Component({
  components: {CopyToFriend, NewProjectCard, ProjectCard},
  computed: {
    ...mapGetters({
      projects: 'listPdfProjects',
      selectedProjectId: 'getSelectedProject'
    })
  }
})
export default class UserHome extends Vue {
  private projects!: ProjectModel[];
  private loaded: boolean = false;
  private selectedProjectId!: number;
  private searchString: string = '';
  private sortBy: string = 'Name';
  private showNewProject: boolean = false;
  private showDeleteConfirm: boolean = false;
  private isDeleting: boolean = false;

  private get filteredProjects(): ProjectModel[] {

    let filteredOnSearch = this.projects;

    if (this.searchString.length > 0) {
      filteredOnSearch = this.projects.filter((p: ProjectModel) => {
        if (p.projectDescription.toUpperCase().indexOf(this.searchString.toUpperCase()) >= 0) {
          return true;
        }
        return p.projectName.toUpperCase().indexOf(this.searchString.toUpperCase()) >= 0;

      });
    }

    if (this.sortBy === 'Lesson Name') {
      return filteredOnSearch.sort((a, b) => (a.projectName > b.projectName) ? 1 : -1);
    } else if (this.sortBy === 'File Name') {
      return filteredOnSearch.sort((a, b) => (a.fileName > b.fileName) ? 1 : -1);
    } else {
      return filteredOnSearch.sort((a, b) => (a.created > b.created) ? 1 : -1);
    }

  }

  private async mounted() {

    if (this.$store.getters.getUserModel) {

      await this.$store.dispatch('loading', true);
      await this.$store.dispatch('fetchProjects');
      await this.$store.dispatch('loadLearningAreas');
      await this.$store.dispatch('loadStages');
      await this.$store.dispatch('listUserProjects');
      await this.$store.dispatch('setCurrentProject', null);
      await this.$store.dispatch('loading', false);

    }

  }

  private navTo(id: number) {
    this.$router.push('/preview/' + id);
  }

  private newProject() {
    this.$store.dispatch('resetProject');
    this.showNewProject = true;
  }

  private deleteSelectedProject() {
    if (!this.selectedProjectId) {
      return;
    }
    this.isDeleting = true;
    this.$store.dispatch('deleteProject', this.selectedProjectId)
        .then(() => {
          this.showDeleteConfirm = false;
          this.isDeleting = false;
        })
        .catch((err) => {
          this.isDeleting = false;
        });
  }

  private getProjectImage(project: ProjectModel): string {
    const url = UrlMethods.getServerURL('/projectPageImage') + '/' + project.uid + '/' + project.id + '/1';
    return url;
  }

  private async newProjectCreated() {
    this.showNewProject = false;
    await this.$store.dispatch('loading', true);
    await this.$store.dispatch('listUserProjects');
    await this.$store.dispatch('loading', false);
  }

  private newProjectCancelled() {
    this.showNewProject = false;
  }


}
